import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Feature, Button, useWindowSize } from 'lition-shared-components';

import { NEW_SITE_URL, PRODUCT_CERTIFICATE_NAMES, STYLE_GAP_SIZES } from '../../../constants';
import Collapse from '../../__atoms__/Collapse2';
import {
    DEFAULT_UNIT_LABELS,
    DEFAULT_PRICE_DETAILS_LABELS,
    DEFAULT_TARIFF_ADVANTAGES_DATA,
    DEFAULT_TARIFF_DETAILS_LABELS,
    DEFAULT_TARIFF_REMARKS,
    DEFAULT_PRODUCER_TARIFF_LABELS
} from './constants';
import './ProducerTariff.css';
import ProductAdvantagesSection from '../../__sections__/ProductAdvantagesSection';

// TODO: cover by tests after separating productAdvantages section
const ProducerTariff = ({
    className,
    // TODO: unite all labels data
    producerTariffLabels,
    tariffAdvantagesData,
    tariffRemarks,
    tariffDetailsLabels,
    priceDetailsLabels,
    unitLabels,
    productAdvantages,
    productCertificates,
    producerSpecification,
    agbUrl
}) => {
    // TODO: remove this undefined condition and update, after implementing defaultExpanded prop for Collapse2
    const isExpanded = useWindowSize() > 748 || undefined;

    const constructUnits = labels => {
        const {
            currency = DEFAULT_UNIT_LABELS.currency,
            secondaryCurrency = DEFAULT_UNIT_LABELS.secondaryCurrency,
            month = DEFAULT_UNIT_LABELS.month,
            months = DEFAULT_UNIT_LABELS.months,
            year = DEFAULT_UNIT_LABELS.year,
            energy = DEFAULT_UNIT_LABELS.energy,
            approximately = DEFAULT_UNIT_LABELS.approximately
        } = labels;

        return {
            currency,
            secondaryCurrency,
            month,
            months,
            year,
            approximately,
            currencyPerMonth: `${currency}/${month}`,
            currencyPerYear: `${currency}/${year}`,
            secondaryCurrencyPerEnergy: `${secondaryCurrency}/${energy}`,
            energyPerYear: `${energy}/${year}`
        };
    };

    const prepareProductAdvantages = () => productAdvantages.map(advantage => {
        const advantageData = tariffAdvantagesData[advantage];

        if (!advantageData) {
            return null;
        }

        const { iconLink, label } = advantageData;
        const isDynamicAdvantage = producerSpecification[advantage];

        const advantageLabel = isDynamicAdvantage ? producerSpecification[advantage] : label;
        return { id: advantageLabel, iconLink, description: advantageLabel };
    });

    const {
        currency,
        currencyPerMonth,
        month,
        year,
        currencyPerYear,
        secondaryCurrencyPerEnergy,
        energyPerYear,
        approximately
    } = constructUnits(unitLabels);

    const { footnoteSign = DEFAULT_PRODUCER_TARIFF_LABELS.footnoteSign } = producerTariffLabels;

    const collapseClasses = classNames(
        'ltn-de-producer-tariff__accordion',
        isExpanded ? 'ltn-de-producer-tariff__accordion_expanded' : 'ltn-de-producer-tariff__accordion_collapsed'
    );

    const renderTariffDetails = () => {
        const TARIFF_DETAILS = [
            'provider',
            'name', // Producer name
            'paymentPeriod', // Hardcoded 'Monthly' payment period
            'monthlyBasePrice', // Subscription fee (€/month (with VAT))
            'workingPrice', // Price per energy (Cent/kWh (with vat))
            'contractPeriodDescription',
            'terminationPeriod', // Hardcoded '1' month
            'guaranteePricePeriod'
        ];

        const tariffUnits = {
            name: footnoteSign,
            monthlyBasePrice: currencyPerMonth,
            workingPrice: secondaryCurrencyPerEnergy,
            terminationPeriod: month,
            guaranteePricePeriod: ''
        };

        return (
            <>
                {TARIFF_DETAILS.map(tariffDetail => (
                    <Feature
                        key={tariffDetail}
                        classes="ltn-de-producer-tariff__list-feature"
                        characteristic={tariffDetailsLabels[tariffDetail]}
                        value={`${producerSpecification[tariffDetail] ? producerSpecification[tariffDetail] : ''}${
                            tariffUnits[tariffDetail] ? ` ${tariffUnits[tariffDetail]}` : ''
                        }`}
                    />
                ))}
                {!isExpanded && (
                    <div className="ltn-de-producer-tariff__remarks ltn-de-producer-tariff__description-remarks">
                        {renderTariffDetailsRemarks()}
                    </div>
                )}
            </>
        );
    };

    const renderPriceDetails = () => {
        const PRICE_DETAILS = [
            'consumption', // Year consumption (kWh/year)
            'workingPriceWithNetto', // Price per energy (Cent/kWh (with(without) vat))
            'yearlyWorkingPrice', // Price per estimated consumption (€/year)
            'monthlyBasePriceWithNetto', // Subscription fee (€/month (with(without) VAT))
            'yearlyPrice', // Total year price (€/year)
            'monthlyPrice' // Estimated month price (€/month)
        ];

        const priceSpecification = {
            consumption: {
                label: priceDetailsLabels.consumption || DEFAULT_PRICE_DETAILS_LABELS.consumption,
                value: producerSpecification.consumption || '',
                unit: energyPerYear
            },
            workingPriceWithNetto: {
                preLabel: producerTariffLabels.multiply,
                label: priceDetailsLabels.workingPriceWithNetto || DEFAULT_PRICE_DETAILS_LABELS.workingPriceWithNetto,
                value: `${producerSpecification.workingPrice || ''} ${
                    producerSpecification.workingPriceNetto ? `(${producerSpecification.workingPriceNetto})` : ''
                }`,
                unit: secondaryCurrencyPerEnergy
            },
            yearlyWorkingPrice: {
                preLabel: producerTariffLabels.equal,
                label: priceDetailsLabels.yearlyWorkingPrice || DEFAULT_PRICE_DETAILS_LABELS.yearlyWorkingPrice,
                value: producerSpecification.yearlyWorkingPrice || '',
                unit: currencyPerYear
            },
            monthlyBasePriceWithNetto: {
                preLabel: producerTariffLabels.plus,
                label: priceDetailsLabels.monthlyBasePriceWithNetto || DEFAULT_PRICE_DETAILS_LABELS.yearlyPrice,
                value: `${producerSpecification.monthlyBasePrice || ''} ${
                    producerSpecification.monthlyBasePriceNetto ?
                        `(${producerSpecification.monthlyBasePriceNetto})` : ''
                }`,
                unit: currencyPerMonth
            },
            yearlyPrice: {
                preLabel: producerTariffLabels.equal,
                label: priceDetailsLabels.yearlyPrice || DEFAULT_PRICE_DETAILS_LABELS.yearlyPrice,
                value: producerSpecification.yearlyPrice || '',
                unit: currencyPerYear
            },
            monthlyPrice: {
                label: priceDetailsLabels.monthlyPrice || DEFAULT_PRICE_DETAILS_LABELS.monthlyPrice,
                value: producerSpecification.monthlyPrice ?
                    `${approximately} ${producerSpecification.monthlyPrice}` : '',
                unit: currencyPerMonth
            }
        };

        if (producerSpecification.yearlySavings) {
            PRICE_DETAILS.push('yearlySavings'); // Estimated year savings (versus default tariff) (€/year)
            priceSpecification.yearlySavings = {
                label: priceDetailsLabels.yearlySavings || DEFAULT_PRICE_DETAILS_LABELS.yearlySavings,
                value: producerSpecification.yearlySavings ?
                    `${approximately} ${producerSpecification.yearlySavings}` : '',
                unit: currencyPerYear
            };
        }

        return (
            <>
                {PRICE_DETAILS.map(priceDetail => (
                    <Feature
                        key={priceDetail}
                        classes="ltn-de-producer-tariff__list-feature ltn-de-producer-tariff__price-feature"
                        characteristic={(
                            <div className="ltn-de-producer-tariff__price-detail">
                                <span
                                    className={classNames(
                                        'ltn-de-producer-tariff__price-detail-prelabel',
                                        isExpanded ?
                                            'ltn-de-producer-tariff__price-detail-prelabel_expanded' :
                                            'ltn-de-producer-tariff__price-detail-prelabel_collapsed'
                                    )}
                                >
                                    {priceSpecification[priceDetail].preLabel ?
                                        priceSpecification[priceDetail].preLabel : null}
                                </span>
                                <span className="ltn-de-producer-tariff__price-detail-label">
                                    {priceSpecification[priceDetail].label}
                                </span>
                            </div>
                        )}
                        value={`${priceSpecification[priceDetail].value || ''} ${priceSpecification[priceDetail].unit}`}
                    />
                ))}
                {!isExpanded && (
                    <div className="ltn-de-producer-tariff__calculate-remarks">{renderPriceDetailsRemarks()}</div>
                )}
            </>
        );
    };

    const renderTariffDetailsRemarks = () => {
        const {
            producer = DEFAULT_TARIFF_REMARKS.producer,
            guaranteePricePeriod = DEFAULT_TARIFF_REMARKS.guaranteePricePeriod
        } = tariffRemarks;

        return (
            <>
                <p className="ltn-de-producer-tariff__remark">
                    <span className="ltn-de-producer-tariff__footnote-sign">{footnoteSign.repeat(1)}</span>
                    <span>{producer}</span>
                </p>
                <p className="ltn-de-producer-tariff__remark">
                    <span className="ltn-de-producer-tariff__footnote-sign">{footnoteSign.repeat(2)}</span>
                    <span>{guaranteePricePeriod}</span>
                </p>
            </>
        );
    };

    const renderPriceDetailsRemarks = () => {
        const { estimatedYearlySavings = DEFAULT_TARIFF_REMARKS.estimatedYearlySavings } = tariffRemarks;

        return (
            <p className="ltn-de-producer-tariff__remark">
                <span className="ltn-de-producer-tariff__footnote-sign">{footnoteSign.repeat(3)}</span>
                <span>{estimatedYearlySavings}</span>
            </p>
        );
    };

    const renderSummary = () => (
        <>
            <Feature
                classes="ltn-de-producer-tariff__summary-year-price"
                characteristic={isExpanded ? null : producerTariffLabels.summary}
                value={(
                    <>
                        <p className="ltn-de-producer-tariff-summary__price">
                            {producerSpecification.yearlyPrice || ''}
                            {currency}
                        </p>
                        <p className="ltn-de-producer-tariff-summary__period-unit">{`/${year}`}</p>
                    </>
                )}
            />
            <div className="ltn-de-producer-tariff__summary-month-discount">
                <p className="ltn-de-producer-tariff__summary-discount">
                    {producerSpecification.monthlyPrice ? `${approximately} ` : ''}
                    {producerSpecification.monthlyPrice}
                    {currency}
                </p>
                <p className="ltn-de-producer-tariff-summary__period-unit">{`/${month}`}</p>
            </div>
            <p
                className={classNames(
                    'ltn-de-producer-tariff__remark',
                    'ltn-de-producer-tariff-summary__remark',
                    isExpanded ?
                        'ltn-de-producer-tariff-summary__remark_expanded' :
                        'ltn-de-producer-tariff-summary__remark_collapsed'
                )}
            >
                {tariffRemarks.totalPrice}
            </p>
            <Button
                onClick={() => {
                    if (window.self !== window.top) {
                        window.parent.postMessage({ contract: producerSpecification }, '*');
                    }
                }}
                className="ltn-de-producer-tariff__signup-button"
            >
                {producerTariffLabels.signupButton}
            </Button>
            {agbUrl && (
                <p className="ltn-de-producer-tariff__details">
                    <a className="ltn-de-producer-tariff__link" href={agbUrl} target="_blank" rel="noreferrer">
                        {producerTariffLabels?.agbLinkLabel}
                    </a>
                    <span>
                        {' '}
                        {producerTariffLabels.detailsTextLabel}
                    </span>
                </p>
            )}
        </>
    );

    return (
        <div
            className={classNames(
                'ltn-de-producer-tariff',
                isExpanded ? 'ltn-de-producer-tariff_expanded' : 'ltn-de-producer-tariff_collapsed',
                className
            )}
        >
            <Collapse
                isActive={isExpanded}
                className={classNames(
                    'ltn-de-producer-tariff__head-block',
                    collapseClasses,
                    isExpanded ? 'ltn-de-producer-tariff__head-block_expanded' :
                        'ltn-de-producer-tariff__head-block_collapsed'
                )}
                label={producerTariffLabels.advantages}
            >
                <ProductAdvantagesSection
                    className="ltn-de-producer-tariff__advantages-section"
                    advantages={prepareProductAdvantages()}
                    config={{ advantagesGap: isExpanded ? STYLE_GAP_SIZES.STANDARD : STYLE_GAP_SIZES.SMALL }}
                    showCertificates={!!isExpanded}
                    certificates={productCertificates}
                />
            </Collapse>
            <div className="ltn-de-producer-tariff__specifications">
                <div
                    className={classNames(
                        'ltn-de-producer-tariff__tariff-details',
                        isExpanded ?
                            'ltn-de-producer-tariff__tariff-details_expanded' :
                            'ltn-de-producer-tariff__tariff-details_collapsed'
                    )}
                >
                    <Collapse
                        isActive={isExpanded}
                        className={classNames('ltn-de-producer-tariff__tariff-details-accordion', collapseClasses)}
                        label={producerTariffLabels.tariffDetails}
                    >
                        {renderTariffDetails()}
                    </Collapse>
                    <Collapse
                        isActive={isExpanded}
                        className={classNames('ltn-de-producer-tariff__price-details-accordion', collapseClasses)}
                        label={producerTariffLabels.priceDetails}
                    >
                        {renderPriceDetails()}
                    </Collapse>
                </div>
                {isExpanded && (
                    <div className="ltn-de-producer-tariff__description-calculate-remarks">
                        {renderTariffDetailsRemarks()}
                        {renderPriceDetailsRemarks()}
                    </div>
                )}
            </div>
            <Collapse
                isActive
                className={classNames(
                    'ltn-de-producer-tariff__summary-section',
                    'ltn-de-producer-tariff__accordion',
                    isExpanded ?
                        'ltn-de-producer-tariff__accordion_expanded' :
                        'ltn-de-producer-tariff__summary-section_collapsed'
                )}
                label={isExpanded ? producerTariffLabels.summary : null}
            >
                {renderSummary()}
            </Collapse>
        </div>
    );
};

ProducerTariff.propTypes = {
    className: PropTypes.string,
    producerTariffLabels: PropTypes.objectOf(PropTypes.string),
    unitLabels: PropTypes.objectOf(PropTypes.string),
    tariffAdvantagesData: PropTypes.objectOf(
        PropTypes.shape({
            iconLink: PropTypes.string,
            label: PropTypes.string
        })
    ),
    tariffRemarks: PropTypes.shape(),
    tariffDetailsLabels: PropTypes.shape(),
    priceDetailsLabels: PropTypes.objectOf(PropTypes.string),
    productAdvantages: PropTypes.arrayOf(
        PropTypes.oneOf(['eco', 'regional', 'security', 'change', 'withdraw', 'contractPeriodDescriptionExtended'])
    ),
    productCertificates: PropTypes.arrayOf(
        PropTypes.oneOf([PRODUCT_CERTIFICATE_NAMES.OK_POWER, PRODUCT_CERTIFICATE_NAMES.TUV_NORD])
    ),
    producerSpecification: PropTypes.shape(),
    agbUrl: PropTypes.string
};

ProducerTariff.defaultProps = {
    className: undefined,
    producerTariffLabels: DEFAULT_PRODUCER_TARIFF_LABELS,
    tariffAdvantagesData: DEFAULT_TARIFF_ADVANTAGES_DATA,
    tariffRemarks: DEFAULT_TARIFF_REMARKS,
    tariffDetailsLabels: DEFAULT_TARIFF_DETAILS_LABELS,
    priceDetailsLabels: DEFAULT_PRICE_DETAILS_LABELS,
    unitLabels: DEFAULT_UNIT_LABELS,
    productAdvantages: ['eco', 'regional', 'security', 'change', 'withdraw', 'contractPeriodDescriptionExtended'],
    productCertificates: [PRODUCT_CERTIFICATE_NAMES.OK_POWER, PRODUCT_CERTIFICATE_NAMES.TUV_NORD],
    producerSpecification: {},
    agbUrl: `${NEW_SITE_URL}/terms-of-service`
};

export default ProducerTariff;
