import axios from 'axios';
import moment from 'moment';
import get from 'lodash/get';
import pick from 'lodash/pick';
import padStart from 'lodash/padStart';
import contentfulClient from './contentful';
import partnersContentfulClient from './contentful/partners';
import memoryCache from '../memoryCache';

import {
    HOLIDAYS_URL,
    FOOTER_CONTENT_ID,
    DEFAULT_THANK_YOU_PAGE_CONTENT_ID,
    GOOD_ENERGY_CAMPAIGN_CONTENT_MODEL_ID,
    SIGNUP_PAGE_CONTENT_ID,
    PRODUCERS_PAGE_CONTENT_ID,
    PRODUCER_DETAILS_PAGE_CONTENT_ID,
    BACKEND_API,
    NOT_FOUND_PAGE_ID,
    ERROR_PAGE_ID
} from '../../constants';
import { isTestEnv } from '../helpers/env';

moment.locale('de');

export function getBankHolidays() {
    const dateFormat = 'DD-MM-YYYY';
    const fromDate = moment().utc().format(dateFormat);
    const toDate = moment().utc().add(3, 'year').format(dateFormat);
    const url = `${HOLIDAYS_URL}?action=getHolidaysForDateRange&fromDate=${fromDate}&toDate=${toDate}&country=deu&holidayType=public_holiday`;

    const cacheData = memoryCache.get(url);
    if (cacheData) {
        return cacheData;
    }

    return axios
        .get(url)
        .then(response => {
            const data = get(response, 'data', []).map(
                ({ date }) => `${date.year}-${padStart(date.month, 2, '0')}-${padStart(date.day, 2, '0')}`
            );
            memoryCache.put(url, data);
            return data;
        })
        .catch(error => {
            console.error(error);
            return [];
        });
}

export function handleContentResponse(promise) {
    return promise
        .then(({ data }) => (data && data.content && data.content.rendered) || 'none')
        .catch(error => {
            console.error(error);
            return 'none';
        });
}

export function getFeaturedProducers(producerIds) {
    const url = `${BACKEND_API}/producers/get/`;
    const sortedIds = producerIds.sort();

    const cacheKey = `${url}-${JSON.stringify(sortedIds)}`;
    const cache = memoryCache.get(cacheKey);
    if (cache) {
        return cache;
    }

    return Promise.all(
        producerIds.map(id => axios
            .get(`${url}${id}`)
            .then(response => {
                const { data = {} } = response;
                return {
                    ...pick(data, ['id', 'name', 'picture', 'postcode', 'city', 'description']),
                    type: data.plantType
                };
            })
            .catch(error => {
                console.error(error);
                return null;
            }))
    ).then(producers => {
        const data = producers.filter(producer => producer);
        memoryCache.put(cacheKey, data);
        return data;
    });
}

export async function handleContentfulResponse(promise) {
    return promise
        .then(({ fields }) => fields || 'none')
        .catch(error => {
            console.error(error);
            return 'none';
        });
}

async function handleContentfulRequest(client, token, options = {}) {
    const key = `CONTENTFUL-${token}-${JSON.stringify(options)}`;

    if (!isTestEnv()) {
        console.time(`CACHE_handleContentfulResponse ${key}`);
    }
    const cache = memoryCache.get(key);
    if (!isTestEnv()) {
        console.timeEnd(`CACHE_handleContentfulResponse ${key}`);
    }

    if (cache) {
        return cache;
    }
    console.time(`REAL_handleContentfulResponse ${key}`);
    const contentfulData = await handleContentfulResponse(client.getEntry(token, options));
    console.timeEnd(`REAL_handleContentfulResponse ${key}`);

    if (contentfulData === 'none') {
        throw new Error('The resource could not be found.');
    }

    memoryCache.put(key, contentfulData);
    return contentfulData;
}

export function getGoodEnergyCampaignContentData() {
    return handleContentfulRequest(contentfulClient, GOOD_ENERGY_CAMPAIGN_CONTENT_MODEL_ID);
}

export function getSignupPageContent(config = { locale: 'de' }) {
    return handleContentfulRequest(contentfulClient, SIGNUP_PAGE_CONTENT_ID, config);
}

export function getProducersPageContent() {
    return handleContentfulRequest(contentfulClient, PRODUCERS_PAGE_CONTENT_ID);
}

export function getProducerDetailsPageContent() {
    return handleContentfulRequest(contentfulClient, PRODUCER_DETAILS_PAGE_CONTENT_ID);
}

export function getLandingContent(token) {
    return handleContentfulRequest(partnersContentfulClient, token, { include: 10 });
}

export function getFooterContent() {
    return handleContentfulRequest(partnersContentfulClient, FOOTER_CONTENT_ID);
}

export function getDefaultThankYouPageContent() {
    return handleContentfulRequest(partnersContentfulClient, DEFAULT_THANK_YOU_PAGE_CONTENT_ID, { include: 10 });
}

export function getNotFoundPageContent() {
    return handleContentfulRequest(partnersContentfulClient, NOT_FOUND_PAGE_ID, { include: 10 });
}

export function getErrorPageContent() {
    return handleContentfulRequest(partnersContentfulClient, ERROR_PAGE_ID, { include: 10 });
}
