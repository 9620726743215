import React from 'react';
import PropTypes from 'prop-types';
import { Select, RadioButton } from 'lition-shared-components';

import { ElectricityIcon, HeatWaves, BatteryMoon, HouseHold, EnergyMeter, HT, NT } from '../../../__icons__';
import MeteringInput from '../../../__atoms__/MeteringInput';

import { ENERGY_TYPES } from '../../../../constants';

import {
    TIME_DEPENDING_RADIO_BUTTON_NAMES as RADIO_BUTTON_NAMES,
    TIME_DEPENDING_FIELD_NAMES as FIELD_NAMES,
    TIME_DEPENDING_DEFAULT_LABELS as DEFAULT_LABELS,
    HEAT_FORM_METER_TYPE_OPTIONS as METER_TYPE_OPTIONS,
    HEAT_CONSUMPTION_OPTIONS,
    CUBIC_METER,
    DIVISION_TOOLTIP_VALUES
} from '../constants';

import { getConsumptionIcons } from '../utils';

export default function HeatForm({
    labels,
    onChange,
    onSubmit,
    formData,
    children,
    styles,
    activeRadioButtonName,
    onRadioButtonChange
}) {
    const renderRadioButtons = () => (
        <div className="ltn-de-tariff-calculator-form-radio-buttons">
            <RadioButton
                icon={<HeatWaves />}
                label={labels.heatPumpRadioButton}
                name={RADIO_BUTTON_NAMES.heatPump}
                checked={activeRadioButtonName === RADIO_BUTTON_NAMES.heatPump}
                onChange={onRadioButtonChange}
                className="ltn-de-radio-button-version2"
                styles={{
                    accentColor: styles?.accentColor
                }}
            />
            <RadioButton
                icon={<BatteryMoon />}
                label={labels.nightStorageHeatingRadioButton}
                name={RADIO_BUTTON_NAMES.nightStorageHeating}
                checked={activeRadioButtonName === RADIO_BUTTON_NAMES.nightStorageHeating}
                onChange={onRadioButtonChange}
                className="ltn-de-radio-button-version2"
                styles={{
                    accentColor: styles?.accentColor
                }}
            />
            <RadioButton
                icon={<HouseHold />}
                label={labels.doubleTariffMeterRadioButton}
                name={RADIO_BUTTON_NAMES.doubleTariffMeter}
                checked={activeRadioButtonName === RADIO_BUTTON_NAMES.doubleTariffMeter}
                onChange={onRadioButtonChange}
                className="ltn-de-radio-button-version2"
                styles={{
                    accentColor: styles?.accentColor
                }}
            />
        </div>
    );

    const renderDoubleMeterings = () => (
        <>
            <MeteringInput
                className="ltn-de-electricity-consumption ltn-de-tariff-calc-form-field"
                onChange={onChange}
                name={FIELD_NAMES.dayConsumption}
                inputValue={formData[FIELD_NAMES.dayConsumption]}
                placeholder={labels.consumptionPlaceholder}
                rangerSliderIcons={getConsumptionIcons(ENERGY_TYPES.GAS)}
                measurerIcon={<HT />}
                meter={DEFAULT_LABELS.meter}
                optionTooltip={CUBIC_METER}
                divisionOptionTooltipValue={DIVISION_TOOLTIP_VALUES[activeRadioButtonName].dayConsumption}
                consumptionOptions={HEAT_CONSUMPTION_OPTIONS[activeRadioButtonName].dayConsumption}
            />
            <MeteringInput
                className="ltn-de-electricity-consumption ltn-de-tariff-calc-form-field"
                onChange={onChange}
                name={FIELD_NAMES.nightConsumption}
                inputValue={formData[FIELD_NAMES.nightConsumption]}
                placeholder={labels.consumptionPlaceholder}
                rangerSliderIcons={getConsumptionIcons(ENERGY_TYPES.GAS)}
                measurerIcon={<NT />}
                meter={DEFAULT_LABELS.meter}
                optionTooltip={CUBIC_METER}
                divisionOptionTooltipValue={DIVISION_TOOLTIP_VALUES[activeRadioButtonName].nightConsumption}
                consumptionOptions={HEAT_CONSUMPTION_OPTIONS[activeRadioButtonName].nightConsumption}
            />
        </>
    );

    const renderSingleMetering = () => (
        <MeteringInput
            className="ltn-de-electricity-consumption ltn-de-tariff-calc-form-field"
            onChange={onChange}
            name={FIELD_NAMES.consumption}
            inputValue={formData[FIELD_NAMES.consumption]}
            placeholder={labels.consumptionPlaceholder}
            rangerSliderIcons={getConsumptionIcons(ENERGY_TYPES.GAS)}
            measurerIcon={<ElectricityIcon />}
            meter={DEFAULT_LABELS.meter}
            optionTooltip={CUBIC_METER}
            divisionOptionTooltipValue={DIVISION_TOOLTIP_VALUES[activeRadioButtonName].consumption}
            consumptionOptions={HEAT_CONSUMPTION_OPTIONS[activeRadioButtonName].consumption}
        />
    );

    const isDoubleSelectMeter = formData[FIELD_NAMES.meterType] === METER_TYPE_OPTIONS[1].value;
    const isDoubleRadioButtonMeter = activeRadioButtonName === RADIO_BUTTON_NAMES.doubleTariffMeter;

    return (
        <>
            {renderRadioButtons()}
            {activeRadioButtonName ? (
                <form onSubmit={onSubmit}>
                    {isDoubleRadioButtonMeter ? null : (
                        <Select
                            name={FIELD_NAMES.meterType}
                            className="ltn-de-select_version2 ltn-de-tariff-calc-form-field"
                            placeholder={labels.meterTypePlaceholder}
                            value={formData[FIELD_NAMES.meterType]}
                            options={METER_TYPE_OPTIONS}
                            onChange={({ name, value }) => onChange({ target: { name, value } })}
                            icon={<EnergyMeter />}
                        />
                    )}
                    {!isDoubleSelectMeter && !isDoubleRadioButtonMeter ? renderSingleMetering() : null}
                    {isDoubleSelectMeter || isDoubleRadioButtonMeter ? renderDoubleMeterings() : null}
                    {children}
                </form>
            ) : null}
        </>
    );
}

HeatForm.propTypes = {
    labels: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formData: PropTypes.shape().isRequired,
    children: PropTypes.element.isRequired,
    onRadioButtonChange: PropTypes.func.isRequired,
    activeRadioButtonName: PropTypes.string.isRequired,
    styles: PropTypes.shape()
};

HeatForm.defaultProps = {
    styles: undefined
};
