import React from 'react';
import LocalStorage from '../../../services/localStorage';
import { PRIVACY_POLICY } from '../../../constants';
import { COOKIES_DISCLAIMER_LABELS } from './constants';
import './CookiesAlert.css';

// TODO[fix]: css position and add to styleguide
class CookiesAlert extends React.Component {
    constructor(props) {
        super(props);
        this.handleCookiesDisclaimerAgreement = this.handleCookiesDisclaimerAgreement.bind(this);

        this.state = { showCookiesDisclaimer: false };
    }

    componentDidMount() {
        const cookiesDisclaimer = LocalStorage.getCookiesAgreementKey();

        if (!cookiesDisclaimer) {
            this.setState({ showCookiesDisclaimer: true });
        }
    }

    handleCookiesDisclaimerAgreement(event) {
        const {
            target: { value }
        } = event;

        LocalStorage.setCookiesAgreementKey(value);
        this.setState({ showCookiesDisclaimer: false });
    }

    render() {
        const { showCookiesDisclaimer } = this.state;

        if (!showCookiesDisclaimer) {
            return null;
        }

        return (
            <div role="alert" className="ltn-cookies-disclaimer">
                <div className="ltn-cookies-disclaimer--text">
                    {COOKIES_DISCLAIMER_LABELS.text}
                    {' '}
                    <a href={PRIVACY_POLICY.href}>{COOKIES_DISCLAIMER_LABELS.link}</a>
                </div>
                <div className="ltn-cookies-disclaimer--buttons-container">
                    <button
                        type="button"
                        value="false"
                        className="ltn-cookies-disclaimer--disagree-button"
                        onClick={this.handleCookiesDisclaimerAgreement}
                    >
                        {COOKIES_DISCLAIMER_LABELS.disagreeButton}
                    </button>
                    <button
                        type="button"
                        value="true"
                        className="ltn-cookies-disclaimer--agree-button"
                        onClick={this.handleCookiesDisclaimerAgreement}
                    >
                        {COOKIES_DISCLAIMER_LABELS.agreeButton}
                    </button>
                </div>
            </div>
        );
    }
}

export default CookiesAlert;
