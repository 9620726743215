import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Toast.css';

// TODO: Move this icons to the icons folder and add the styleguide
const successIcon = (
    <svg
        className="ltn-de-toast-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width="23.5"
        height="23.5"
    >
        <path
            fill="currentColor"
            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
        />
    </svg>
);

const errorIcon = (
    <svg
        width="23.5"
        height="23.5"
        className="ltn-de-toast-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
    >
        <path
            fill="currentColor"
            d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
        />
    </svg>
);

const closeIcon = (
    <svg width="52" height="75" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
        <path
            fill="currentColor"
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
        />
    </svg>
);

const TOAST_TYPES = {
    success: 'success',
    error: 'error'
};

export default function Toast({ className, type, message, onCloseClick }) {
    const [isActive, setActiveState] = useState(true);

    useEffect(() => {
        if (process.browser && message) {
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    const handleCloseToast = () => (onCloseClick ? onCloseClick() : setActiveState(false));
    const classes = classNames('ltn-de-toast', `ltn-de-toast--${type}`, className);

    if (!isActive) {
        return null;
    }

    return (
        <div role="alertdialog" className={classes}>
            {type === TOAST_TYPES.success ? successIcon : errorIcon}
            <h2 className="ltn-de-toast-message">{message}</h2>
            <button type="button" className="ltn-de-toast-close-button" onClick={handleCloseToast}>
                {closeIcon}
            </button>
        </div>
    );
}

Toast.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf([TOAST_TYPES.success, TOAST_TYPES.error]),
    message: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func
};
Toast.defaultProps = {
    className: undefined,
    type: TOAST_TYPES.success,
    onCloseClick: undefined
};
