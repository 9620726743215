import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'lition-shared-components';

// TODO: Cover by tests
// TODO: Render icon and label in the Checkbox component
export default function CheckboxWrapper({ icon, label, name, checked, onChange }) {
    return (
        <div className="ltn-de-tariff-calculator-form-checkbox">
            {icon}
            <p>{label}</p>
            <Checkbox className="ltn-de-checkbox-v2" name={name} checked={checked} onChange={onChange} />
        </div>
    );
}

CheckboxWrapper.propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

CheckboxWrapper.defaultProps = {
    icon: undefined
};
