import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { toAddressFormat } from '../../../services/helpers/converter';

import StandardForm from './StandardForm';
import TimeDependingForm from './TimeDependingForm';

import { FORM_TYPES, LOCATION_FIELD_NAMES } from './constants';

import './TariffCalculationFormV2.css';

// TODO: Adapt props before to send to this component
export default function TariffCalculationFormV2({
    className,
    data: {
        streetName,
        cityName,
        houseNumber,
        zip,
        consumption,
        HT: dayConsumption,
        NT: nightConsumption,
        energySubTypeId
    },
    styles,
    energyTypes,
    labels,
    locale,
    consumptionOptions,
    isSubmitButtonDisabled,
    consumptionIconsType,
    submitDataError,
    validationConfig,
    onSubmit,
    formType,
    isGoogleScriptLoaded,
    metaData,
    isGasChecked,
    isElectricityChecked,
    electricityConsumption,
    gasConsumption,
    formMode
}) {
    const handleSubmit = data => {
        onSubmit({ ...data, metaData });
    };

    const address = toAddressFormat({ streetName, houseNumber, zip, cityName });
    const isTimeDependingForm = formType === FORM_TYPES.mobility || formType === FORM_TYPES.heat;
    const isZipMode = formMode === LOCATION_FIELD_NAMES.zip;

    return (
        <div className={classNames('ltn-de-tariff-calculator-form-container', className)}>
            {isTimeDependingForm ? (
                <TimeDependingForm
                    formType={formType}
                    styles={styles}
                    data={{
                        streetName,
                        cityName,
                        houseNumber,
                        zip,
                        consumption,
                        address,
                        dayConsumption,
                        nightConsumption,
                        energySubTypeId
                    }}
                    onSubmit={handleSubmit}
                    isZipMode={isZipMode}
                />
            ) : (
                <StandardForm
                    address={address}
                    streetName={streetName}
                    cityName={cityName}
                    houseNumber={houseNumber}
                    zip={zip}
                    electricityConsumption={electricityConsumption}
                    gasConsumption={gasConsumption}
                    isElectricityChecked={isElectricityChecked}
                    isGasChecked={isGasChecked}
                    submitDataError={submitDataError}
                    labels={labels}
                    validationConfig={validationConfig}
                    onSubmit={handleSubmit}
                    energyTypes={energyTypes}
                    locale={locale}
                    consumptionOptions={consumptionOptions}
                    isSubmitButtonDisabled={isSubmitButtonDisabled}
                    inputBackground={styles?.inputBackground}
                    accentColor={styles?.accentColor}
                    consumptionIconsType={consumptionIconsType}
                    isGoogleScriptLoaded={isGoogleScriptLoaded}
                    isZipMode={isZipMode}
                />
            )}
        </div>
    );
}

TariffCalculationFormV2.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    className: PropTypes.string,
    data: PropTypes.shape({
        streetName: PropTypes.string,
        cityName: PropTypes.string,
        houseNumber: PropTypes.string,
        zip: PropTypes.string,
        consumption: PropTypes.string,
        HT: PropTypes.string,
        NT: PropTypes.string,
        energySubTypeId: PropTypes.string
    }),
    labels: PropTypes.shape({}),
    submitDataError: PropTypes.string,
    isSubmitButtonDisabled: PropTypes.bool,
    locale: PropTypes.string,
    consumptionOptions: PropTypes.shape({}),
    energyTypes: PropTypes.arrayOf(PropTypes.string),
    validationConfig: PropTypes.shape({}),
    consumptionIconsType: PropTypes.string,
    metaData: PropTypes.shape({}),
    styles: PropTypes.shape({
        inputBackground: PropTypes.string,
        accentColor: PropTypes.string
    }),
    formType: PropTypes.string,
    isGoogleScriptLoaded: PropTypes.bool,
    isGasChecked: PropTypes.bool,
    isElectricityChecked: PropTypes.bool,
    electricityConsumption: PropTypes.string,
    gasConsumption: PropTypes.string,
    formMode: PropTypes.oneOf(['zip', 'address'])
};

TariffCalculationFormV2.defaultProps = {
    className: undefined,
    data: {},
    labels: undefined,
    submitDataError: undefined,
    locale: undefined,
    metaData: undefined,
    consumptionOptions: undefined,
    isSubmitButtonDisabled: false,
    energyTypes: undefined,
    validationConfig: undefined,
    consumptionIconsType: undefined,
    styles: undefined,
    formType: undefined,
    isGoogleScriptLoaded: undefined,
    isGasChecked: false,
    isElectricityChecked: true,
    electricityConsumption: undefined,
    gasConsumption: undefined,
    formMode: undefined
};
