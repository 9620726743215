import { ENERGY_TYPES, HEAT_ENERGY_SUB_TYPE_IDS, MOBILITY_ENERGY_SUB_TYPE_IDS } from '../../../constants';

import { persons, houses, eCars } from '../../__icons__';

import {
    DEFAULT_HOUSE_NUMBER,
    CONSUMPTION_ICONS_TYPE,
    DEFAULT_VALIDATION_CONFIG,
    DEFAULT_LABELS,
    HEAT_FORM_METER_TYPE_OPTIONS,
    TIME_DEPENDING_RADIO_BUTTON_NAMES,
    TIME_DEPENDING_FIELD_NAMES,
    MOBILITY_FORM_METER_TYPE_OPTIONS,
    MOBILITY_CONSUMPTION_OPTIONS
} from './constants';

import { getPossibleAddress } from '../../../services/helpers/places';

export function addDefaultHouseNumberToLocaton(location) {
    const isHasNumber = /\d/;
    const isHasComma = /,/;

    if (isHasNumber.test(location)) {
        return location;
    }

    if (isHasComma.test(location)) {
        return location.replace(',', `, ${DEFAULT_HOUSE_NUMBER}`);
    }

    return `${location} ${DEFAULT_HOUSE_NUMBER}`;
}

export function getConsumptionIcons(energyType, consumptionIconsType) {
    if (energyType === ENERGY_TYPES.ELECTRICITY && consumptionIconsType === CONSUMPTION_ICONS_TYPE.electroMobility) {
        return eCars;
    }

    if (energyType === ENERGY_TYPES.GAS) {
        return houses;
    }

    return persons;
}

export function getCurrentConsumptionData(data) {
    const { isElectricityChecked, electricityConsumption, gasConsumption, validationConfig, labels } = data;

    if (isElectricityChecked) {
        return {
            consumption: electricityConsumption,
            energyType: ENERGY_TYPES.ELECTRICITY,
            minimalValidConsumption:
                validationConfig?.minimalElectricityConsumption ??
                DEFAULT_VALIDATION_CONFIG.minimalElectricityConsumption,
            maximumValidConsumption:
                validationConfig?.maximumElectricityConsumption ??
                DEFAULT_VALIDATION_CONFIG.maximumElectricityConsumption,
            minimalValidConsumptionErrorLabel:
                labels?.minimalElectricityConsumptionError ?? DEFAULT_LABELS.minimalElectricityConsumptionError,
            maximumValidConsumptionErrorLabel:
                labels?.maximumElectricityConsumptionError ?? DEFAULT_LABELS.maximumElectricityConsumptionError
        };
    }

    return {
        consumption: gasConsumption,
        energyType: ENERGY_TYPES.GAS,
        minimalValidConsumption:
            validationConfig?.minimalGasConsumption ?? DEFAULT_VALIDATION_CONFIG.minimalGasConsumption,
        maximumValidConsumption:
            validationConfig?.maximumGasConsumption ?? DEFAULT_VALIDATION_CONFIG.maximumGasConsumption,
        minimalValidConsumptionErrorLabel:
            labels?.minimalGasConsumptionError ?? DEFAULT_LABELS.minimalGasConsumptionError,
        maximumValidConsumptionErrorLabel:
            labels?.maximumGasConsumptionError ?? DEFAULT_LABELS.maximumGasConsumptionError
    };
}

export async function getAddress({ streetName, location, zip, houseNumber, cityName }) {
    const isAddressFromGoogleDropdown = streetName;
    const isAddressFromInput = !streetName && location;

    if (isAddressFromGoogleDropdown && !zip && !houseNumber) {
        const preparedAddress = `${streetName} ${DEFAULT_HOUSE_NUMBER}`;
        const possibleAddress = await getPossibleAddress(preparedAddress);
        return possibleAddress;
    }

    if (isAddressFromInput) {
        const preparedLocation = addDefaultHouseNumberToLocaton(location);
        const possibleAddress = await getPossibleAddress(preparedLocation);
        return possibleAddress;
    }

    return { streetName, zip, houseNumber, cityName };
}

export function getParsedConsumption(consumption) {
    return parseInt(consumption, 10);
}

export function getPreparedSubmitMobilityData(data) {
    const preparedSubmitData = {};
    const meterType = data[TIME_DEPENDING_FIELD_NAMES.meterType];

    if (meterType === MOBILITY_FORM_METER_TYPE_OPTIONS[0].value) {
        preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.consumption] = getParsedConsumption(
            data[TIME_DEPENDING_FIELD_NAMES.consumption]
        );

        preparedSubmitData.energySubTypeId = MOBILITY_ENERGY_SUB_TYPE_IDS.mobility;
    }

    if (meterType === MOBILITY_FORM_METER_TYPE_OPTIONS[1].value) {
        preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.dayConsumption] = getParsedConsumption(
            data[TIME_DEPENDING_FIELD_NAMES.dayConsumption]
        );

        preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.nightConsumption] = getParsedConsumption(
            data[TIME_DEPENDING_FIELD_NAMES.nightConsumption]
        );

        preparedSubmitData.energySubTypeId = MOBILITY_ENERGY_SUB_TYPE_IDS.double;
        preparedSubmitData.isTimeDependingConsumption = true;
    }

    if (meterType === MOBILITY_FORM_METER_TYPE_OPTIONS[2].value) {
        preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.consumption] = getParsedConsumption(
            data[TIME_DEPENDING_FIELD_NAMES.consumption]
        );

        preparedSubmitData.energySubTypeId = MOBILITY_ENERGY_SUB_TYPE_IDS.home;
    }

    return preparedSubmitData;
}

export function getPreparedSubmitHeatData(data, activeRadioButtonName) {
    const preparedSubmitData = {};
    const meterType = data[TIME_DEPENDING_FIELD_NAMES.meterType];

    if (activeRadioButtonName === TIME_DEPENDING_RADIO_BUTTON_NAMES.heatPump) {
        if (meterType === HEAT_FORM_METER_TYPE_OPTIONS[0].value) {
            preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.consumption] = getParsedConsumption(
                data[TIME_DEPENDING_FIELD_NAMES.consumption]
            );

            preparedSubmitData.energySubTypeId = HEAT_ENERGY_SUB_TYPE_IDS.pump.single;
        }

        if (meterType === HEAT_FORM_METER_TYPE_OPTIONS[1].value) {
            preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.dayConsumption] = getParsedConsumption(
                data[TIME_DEPENDING_FIELD_NAMES.dayConsumption]
            );
            preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.nightConsumption] = getParsedConsumption(
                data[TIME_DEPENDING_FIELD_NAMES.nightConsumption]
            );

            preparedSubmitData.energySubTypeId = HEAT_ENERGY_SUB_TYPE_IDS.pump.double;
            preparedSubmitData.isTimeDependingConsumption = true;
        }
    }

    if (activeRadioButtonName === TIME_DEPENDING_RADIO_BUTTON_NAMES.nightStorageHeating) {
        if (meterType === HEAT_FORM_METER_TYPE_OPTIONS[0].value) {
            preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.consumption] = getParsedConsumption(
                data[TIME_DEPENDING_FIELD_NAMES.consumption]
            );

            preparedSubmitData.energySubTypeId = HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.single;
        }

        if (meterType === HEAT_FORM_METER_TYPE_OPTIONS[1].value) {
            preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.dayConsumption] = getParsedConsumption(
                data[TIME_DEPENDING_FIELD_NAMES.dayConsumption]
            );

            preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.nightConsumption] = getParsedConsumption(
                data[TIME_DEPENDING_FIELD_NAMES.nightConsumption]
            );

            preparedSubmitData.energySubTypeId = HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.double;
            preparedSubmitData.isTimeDependingConsumption = true;
        }
    }

    if (activeRadioButtonName === TIME_DEPENDING_RADIO_BUTTON_NAMES.doubleTariffMeter) {
        preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.dayConsumption] = getParsedConsumption(
            data[TIME_DEPENDING_FIELD_NAMES.dayConsumption]
        );

        preparedSubmitData[TIME_DEPENDING_FIELD_NAMES.nightConsumption] = getParsedConsumption(
            data[TIME_DEPENDING_FIELD_NAMES.nightConsumption]
        );

        preparedSubmitData.energySubTypeId = HEAT_ENERGY_SUB_TYPE_IDS.doubleTariff;
        preparedSubmitData.isTimeDependingConsumption = true;
    }

    return preparedSubmitData;
}

export function getMeterTypeByEnergySubTypeId(energySubTypeId) {
    switch (Number(energySubTypeId)) {
        case HEAT_ENERGY_SUB_TYPE_IDS.pump.single:
        case HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.single:
        case MOBILITY_ENERGY_SUB_TYPE_IDS.home: {
            return HEAT_FORM_METER_TYPE_OPTIONS[0].value;
        }

        case HEAT_ENERGY_SUB_TYPE_IDS.pump.double:
        case HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.double:
        case MOBILITY_ENERGY_SUB_TYPE_IDS.double: {
            return HEAT_FORM_METER_TYPE_OPTIONS[1].value;
        }

        case MOBILITY_ENERGY_SUB_TYPE_IDS.mobility: {
            return MOBILITY_FORM_METER_TYPE_OPTIONS[0].value;
        }

        default:
            return undefined;
    }
}

export function getActiveRadioButtonByEnergySubTypeId(energySubTypeId) {
    switch (Number(energySubTypeId)) {
        case HEAT_ENERGY_SUB_TYPE_IDS.pump.single:
        case HEAT_ENERGY_SUB_TYPE_IDS.pump.double: {
            return TIME_DEPENDING_RADIO_BUTTON_NAMES.heatPump;
        }

        case HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.single:
        case HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.double: {
            return TIME_DEPENDING_RADIO_BUTTON_NAMES.nightStorageHeating;
        }

        case HEAT_ENERGY_SUB_TYPE_IDS.doubleTariff: {
            return TIME_DEPENDING_RADIO_BUTTON_NAMES.doubleTariffMeter;
        }

        case MOBILITY_ENERGY_SUB_TYPE_IDS.mobility:
        case MOBILITY_ENERGY_SUB_TYPE_IDS.home:
        case MOBILITY_ENERGY_SUB_TYPE_IDS.double: {
            return undefined;
        }

        default:
            return TIME_DEPENDING_RADIO_BUTTON_NAMES.heatPump;
    }
}

export function getMobilityConsumptionOptions(meterType) {
    if (!meterType) {
        return MOBILITY_CONSUMPTION_OPTIONS[MOBILITY_FORM_METER_TYPE_OPTIONS[0].value];
    }

    return MOBILITY_CONSUMPTION_OPTIONS[meterType];
}
