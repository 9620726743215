import {
    DEFAULT_LABELS,
    MOBILITY_FORM_METER_TYPE_OPTIONS,
    TIME_DEPENDING_RADIO_BUTTON_NAMES,
    TIME_DEPENDING_DEFAULT_LABELS
} from './constants';

import { compileHandlebars } from '../../../services/helpers/handlebars';
import { localizationNumbers } from '../../../services/helpers/localization';

export function validateConsumption(data) {
    const {
        consumption,
        minimalValidConsumption,
        maximumValidConsumption,
        minimalValidConsumptionErrorLabel,
        maximumValidConsumptionErrorLabel
    } = data;

    const isLessThanMinConsumption = consumption < minimalValidConsumption;
    const exceedsMaxConsumption = consumption >= maximumValidConsumption;

    if (isLessThanMinConsumption || exceedsMaxConsumption) {
        const sourceLabel = isLessThanMinConsumption ?
            minimalValidConsumptionErrorLabel :
            maximumValidConsumptionErrorLabel;

        const consumptionData = {
            minimalValidConsumption,
            maximumValidConsumption
        };

        const consumptionErrorString = compileHandlebars(sourceLabel, consumptionData);
        const submitDataError = localizationNumbers(consumptionErrorString);

        return submitDataError;
    }

    return null;
}

export function validateStandardFormData(data) {
    const {
        streetName,
        cityName,
        zip,
        labels,
        consumption,
        minimalValidConsumption,
        maximumValidConsumption,
        minimalValidConsumptionErrorLabel,
        maximumValidConsumptionErrorLabel,
        isZipMode
    } = data;

    const isZipError = !zip;
    const isConsumptionEmptyError = !consumption;

    if (!isZipMode) {
        const isAddressError = !streetName || !cityName;

        if (isAddressError && isConsumptionEmptyError) {
            return labels.tariffCalculationFormEmpty;
        }

        if (isAddressError) {
            return labels.addressEmptyError;
        }
    }

    if (isZipError) {
        // TODO: Need to move zipFieldEmptyError to prop labels @Anton
        return isZipMode ? DEFAULT_LABELS.zipFieldEmptyError : labels.zipEmptyError;
    }

    if (isConsumptionEmptyError) {
        const submitDataError = labels.consumptionEmptyError ?? DEFAULT_LABELS.consumptionEmptyError;
        return submitDataError;
    }

    const isConsumptionError = validateConsumption({
        consumption,
        minimalValidConsumption,
        maximumValidConsumption,
        minimalValidConsumptionErrorLabel,
        maximumValidConsumptionErrorLabel
    });

    if (isConsumptionError) {
        return isConsumptionError;
    }

    return null;
}

// TODO: Add validation for minimal and maximum consumptions
export function validateTimeDependingFormData(data) {
    const { labels, streetName, cityName, zip, consumption, HT, NT, meterType, energySubTypeName, isZipMode } = data;

    const isSingeMeterType =
        meterType === MOBILITY_FORM_METER_TYPE_OPTIONS[0].value ||
        meterType === MOBILITY_FORM_METER_TYPE_OPTIONS[2].value;

    const isMeterTypeEmpty =
        energySubTypeName === TIME_DEPENDING_RADIO_BUTTON_NAMES.doubleTariffMeter ? false : !meterType;

    const isAddressEmpty = !streetName || !cityName;
    const isZipEmpty = !zip;
    const isDayOrNightConsumptionEmpty = !HT || !NT;
    const isConsumptionEmpty = isSingeMeterType ? !consumption : isDayOrNightConsumptionEmpty;
    const isFormEmpty = isAddressEmpty && isConsumptionEmpty;
    const isFormWithMeterTypeEmpty = isMeterTypeEmpty && isFormEmpty;

    if (isFormWithMeterTypeEmpty || isFormEmpty) {
        return labels.tariffCalculationFormEmpty || TIME_DEPENDING_DEFAULT_LABELS.tariffCalculationFormEmpty;
    }

    if (isMeterTypeEmpty) {
        return labels.meterTypeEmptyError || TIME_DEPENDING_DEFAULT_LABELS.meterTypeEmptyError;
    }

    if (isConsumptionEmpty) {
        return labels.consumptionEmptyError || TIME_DEPENDING_DEFAULT_LABELS.consumptionEmptyError;
    }

    if (!isZipMode) {
        if (isAddressEmpty) {
            return labels.addressEmptyError || TIME_DEPENDING_DEFAULT_LABELS.addressEmptyError;
        }

        if (isZipEmpty) {
            return labels.zipEmptyError || TIME_DEPENDING_DEFAULT_LABELS.zipEmptyError;
        }
    }

    if (isZipEmpty) {
        return labels.zipFieldEmptyError || TIME_DEPENDING_DEFAULT_LABELS.zipFieldEmptyError;
    }

    return null;
}
