// TODO: make this units global and reuse them
export const DEFAULT_UNIT_LABELS = {
    currency: '€',
    secondaryCurrency: 'ct',
    month: 'Monat',
    months: 'Monate',
    year: 'Jahr',
    energy: 'kWh',
    approximately: 'ca.'
};

export const DEFAULT_TARIFF_ADVANTAGES_DATA = {
    eco: {
        iconLink:
            'https://images.ctfassets.net/wif4espsdh6s/MTh6mQUi8o4iPUijBnmEP/3d6167a83774ccbcbe23399885c3e0cc/group-6.svg',
        label: '100% Ökostrom'
    },
    regional: {
        iconLink:
            'https://images.ctfassets.net/wif4espsdh6s/3yQBbV5Yk9MW7xF5XKIuV8/2d926b03436225ba70629b3c2d700c7a/group-18.svg',
        label: 'Regionale Erzeugerauswahl'
    },
    security: {
        iconLink:
            'https://images.ctfassets.net/wif4espsdh6s/14NcKmFZJiwSukuSGVuUfI/fc47cd47a2f5a1c675c23da10f33a8f6/group-8.svg',
        label: 'Versorgungssicherheit garantiert'
    },
    change: {
        iconLink:
            'https://images.ctfassets.net/wif4espsdh6s/4GKLC17y5SSQfUjBGToDOX/a1c28ab7d21a9234fdc12dde12cc68b6/group-7.svg',
        label: 'Wechselservice inklusive'
    },
    withdraw: {
        iconLink:
            'https://images.ctfassets.net/wif4espsdh6s/1XtXSVmY00L1gd1YYseAwQ/0ef4182a4f0ae39a9315ed958edc5577/group-10.svg',
        label: '14 Tage Widerrufsrecht'
    },
    contractPeriodDescriptionExtended: {
        iconLink:
            'https://images.ctfassets.net/wif4espsdh6s/50hUk8LmrlsAf5yVWGx1OP/79ffbff7cb056e15d3a7e60d50bb06e4/group-16.svg',
        label: ''
    }
};

export const DEFAULT_PRODUCER_TARIFF_LABELS = {
    advantages: 'Deine Vorteile:',
    tariffDetails: 'Tarifdetails:',
    priceDetails: 'Preisdetails:',
    summary: 'Du zahlst:',
    agbLinkLabel: 'AGB',
    detailsTextLabel: 'zu diesem Tarif',
    signupButton: 'Kunde werden',
    footnoteSign: '*',
    multiply: '×',
    equal: '=',
    plus: '+'
};

export const DEFAULT_PRICE_DETAILS_LABELS = {
    consumption: 'Verbrauchsannahme:',
    workingPriceWithNetto: 'Arbeitspreis pro kWh brutto (netto):',
    yearlyWorkingPrice: 'Arbeitspreis gesamt:',
    monthlyBasePriceWithNetto: 'Grundpreis brutto (netto)',
    yearlyPrice: 'Bruttopreis /Jahr:',
    monthlyPrice: 'Geschätzter Abschlag:',
    yearlySavings: 'Geschätzte Ersparnis:***'
};

export const DEFAULT_TARIFF_DETAILS_LABELS = {
    provider: 'Anbieter:',
    name: 'Gewählter Produzent:',
    paymentPeriod: 'Abschlagszahlung:',
    monthlyBasePrice: 'Grundpreis (brutto):',
    workingPrice: 'Arbeitspreis (brutto):',
    contractPeriodDescription: 'Vertragslaufzeit:',
    terminationPeriod: 'Kündigungsfrist:',
    guaranteePricePeriod: 'Energie-Preisgarantie:**'
};

export const DEFAULT_TARIFF_REMARKS = {
    producer: 'dein Erzeuger ist optional täglich wechselbar.',
    guaranteePricePeriod: 'ohne Abgaben, Umlagen und Netzentgelte. Diese sind von stromee nicht beeinflussbar.',
    estimatedYearlySavings: 'Diesen Betrag sparst du gegenüber dem Grundversorger in deinem Netzgebiet.',
    totalPrice: 'Gesamtpreis inkl. aller Steuern, Abgaben und  Netzentgelte (Ausnahmen Smart Meter)'
};
