import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput } from 'lition-shared-components';
import get from 'lodash/get';

import { GasIcon2, ElectricityIcon2, HouseIcon, ElectricityIcon, LocationIcon } from '../../__icons__';
import LocationInputV2 from '../../__atoms__/LocationInputV2/LocationInputV2';
import MeteringInput from '../../__atoms__/MeteringInput';

import { ENERGY_TYPES, CONSUMPTION_OPTIONS_ELECTRICITY, CONSUMPTION_OPTIONS_GAS } from '../../../constants';

import { isTestEnv } from '../../../services/helpers/env';

import CheckboxWrapper from './CheckboxWrapper';
import {
    DEFAULT_LABELS,
    DEFAULT_VALIDATION_CONFIG,
    DEFAULT_HOUSE_NUMBER,
    CUBIC_METER,
    DIVISION_TOOLTIP_VALUES
} from './constants';
import { getConsumptionIcons, getAddress, getCurrentConsumptionData } from './utils';
import { validateStandardFormData } from './validation';

// TODO: Move all state names to constants
// TODO: Add primary validation, because we call getAddress function each time when click on submit button
export default class StandardForm extends React.Component {
    constructor(props) {
        super(props);
        const {
            streetName,
            cityName,
            houseNumber,
            zip,
            electricityConsumption,
            gasConsumption,
            address,
            isGasChecked,
            isElectricityChecked,
            submitDataError
        } = props;

        this.state = {
            address,
            houseNumber,
            streetName,
            zip,
            cityName,
            electricityConsumption,
            gasConsumption,
            isElectricityChecked,
            isGasChecked,
            submitDataError,
            location: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();

        const {
            gasConsumption,
            electricityConsumption,
            isElectricityChecked,
            houseNumber,
            streetName,
            zip,
            cityName,
            location
        } = this.state;

        const { validationConfig, labels, onSubmit, isZipMode } = this.props;

        const possibleAddress = isZipMode ? { zip } : await getAddress({ zip, houseNumber, streetName, location });

        // TODO: Need to think how we can refactor this @Anton
        const address = isZipMode ?
            { zip } :
            {
                zip: get(possibleAddress, 'zip', zip),
                streetName: get(possibleAddress, 'streetName', streetName),
                cityName: get(possibleAddress, 'cityName', cityName),
                houseNumber: get(possibleAddress, 'houseNumber', houseNumber) || DEFAULT_HOUSE_NUMBER
            };

        const consumptionData = getCurrentConsumptionData({
            isElectricityChecked,
            electricityConsumption,
            gasConsumption,
            validationConfig,
            labels
        });

        // TODO: Add validation to check submit params types @Anton
        const validationError = validateStandardFormData({
            labels,
            zip: address.zip,
            streetName: address?.streetName,
            cityName: address?.cityName,
            consumption: consumptionData?.consumption,
            minimalValidConsumption: consumptionData.minimalValidConsumption,
            maximumValidConsumption: consumptionData.maximumValidConsumption,
            minimalValidConsumptionErrorLabel: consumptionData.minimalValidConsumptionErrorLabel,
            maximumValidConsumptionErrorLabel: consumptionData.maximumValidConsumptionErrorLabel,
            isZipMode
        });

        if (validationError) {
            if (!isTestEnv()) {
                console.error('TariffCalculationFormV2:', validationError);
            }

            this.setState({ submitDataError: validationError });
            return;
        }

        const submitObject = {
            consumption: parseInt(consumptionData.consumption, 10),
            energyType: consumptionData.energyType,
            houseNumber: address?.houseNumber,
            streetName: address?.streetName,
            cityName: address?.cityName,
            zip: address.zip
        };

        onSubmit(submitObject);
    }

    handleCheckboxChange(event) {
        const { name, checked } = event.target;
        const { isGasChecked, isElectricityChecked } = this.state;

        if (name === ENERGY_TYPES.ELECTRICITY) {
            this.setState({ isElectricityChecked: checked, isGasChecked: !isGasChecked });
        } else {
            this.setState({ isGasChecked: checked, isElectricityChecked: !isElectricityChecked });
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value, submitDataError: null });
    }

    handleAddressChange(address) {
        this.setState({
            cityName: get(address, 'cityName', ''),
            houseNumber: get(address, 'houseNumber', ''),
            streetName: get(address, 'streetName', ''),
            zip: get(address, 'zip', '')
        });
    }

    renderCheckboxes() {
        const { energyTypes, labels } = this.props;

        if (energyTypes?.length !== 2) {
            return null;
        }

        const { isElectricityChecked, isGasChecked } = this.state;

        return (
            <div className="ltn-de-tariff-calculator-form-checkboxes">
                <CheckboxWrapper
                    icon={<ElectricityIcon2 />}
                    label={labels.electricityCheckboxName}
                    name={ENERGY_TYPES.ELECTRICITY}
                    checked={isElectricityChecked}
                    onChange={this.handleCheckboxChange}
                />
                <CheckboxWrapper
                    icon={<GasIcon2 />}
                    label={labels.gasCheckboxName}
                    name={ENERGY_TYPES.GAS}
                    checked={isGasChecked}
                    onChange={this.handleCheckboxChange}
                />
            </div>
        );
    }

    renderLocationInput() {
        const { isZipMode, labels, inputBackground, accentColor, isGoogleScriptLoaded, locale } = this.props;
        const { address, zip } = this.state;

        if (isZipMode) {
            return (
                // TODO: Need to add a zip icon for this input
                <TextInput
                    className="ltn-de-text-input_version2"
                    name="zip"
                    // TODO: Need to move to labels prop
                    placeholder={DEFAULT_LABELS.zipPlaceholder}
                    value={zip}
                    onChange={this.handleChange}
                    icon={<LocationIcon />}
                    styles={{ accentColor }}
                />
            );
        }

        return (
            <LocationInputV2
                onAddressChange={this.handleAddressChange}
                placeholder={labels.address}
                value={address}
                locale={locale}
                name="location"
                onChange={this.handleChange}
                backgroundColor={inputBackground}
                primaryColor={accentColor}
                isGoogleScriptLoaded={isGoogleScriptLoaded}
            />
        );
    }

    render() {
        const {
            labels,
            consumptionOptions,
            isSubmitButtonDisabled,
            inputBackground,
            accentColor,
            consumptionIconsType
        } = this.props;

        const {
            electricityConsumption,
            gasConsumption,
            isElectricityChecked,
            isGasChecked,
            submitDataError,
            streetName,
            location,
            zip
        } = this.state;

        const isShowElectricityConsumption = isElectricityChecked && (zip || streetName || location);
        const isShowGasConsumption = isGasChecked && (zip || streetName || location);

        return (
            <>
                {this.renderCheckboxes()}
                <form className="ltn-de-tariff-calculator-form" onSubmit={this.handleSubmit}>
                    {this.renderLocationInput()}
                    {isShowElectricityConsumption && (
                        <MeteringInput
                            className="ltn-de-electricity-consumption"
                            onChange={this.handleChange}
                            name="electricityConsumption"
                            inputValue={electricityConsumption}
                            placeholder={labels.electricityConsumption}
                            rangerSliderIcons={getConsumptionIcons(ENERGY_TYPES.ELECTRICITY, consumptionIconsType)}
                            measurerIcon={<ElectricityIcon />}
                            meter={DEFAULT_LABELS.meter}
                            consumptionOptions={get(
                                consumptionOptions,
                                ENERGY_TYPES.ELECTRICITY,
                                CONSUMPTION_OPTIONS_ELECTRICITY
                            )}
                            backgroundColor={inputBackground}
                            primaryColor={accentColor}
                        />
                    )}
                    {isShowGasConsumption && (
                        <MeteringInput
                            className="ltn-de-gas-consumption"
                            onChange={this.handleChange}
                            name="gasConsumption"
                            inputValue={gasConsumption}
                            placeholder={labels.gasConsumption}
                            consumptionOptions={get(consumptionOptions, ENERGY_TYPES.GAS, CONSUMPTION_OPTIONS_GAS)}
                            rangerSliderIcons={getConsumptionIcons(ENERGY_TYPES.GAS)}
                            measurerIcon={<HouseIcon />}
                            meter={DEFAULT_LABELS.meter}
                            backgroundColor={inputBackground}
                            primaryColor={accentColor}
                            divisionOptionTooltipValue={DIVISION_TOOLTIP_VALUES.gas}
                            optionTooltip={CUBIC_METER}
                        />
                    )}
                    <TextInput.Error>{submitDataError}</TextInput.Error>
                    <Button
                        className="ltn-de-tariff-calculator-form-submit"
                        disabled={isSubmitButtonDisabled}
                        type="submit"
                        buttonColor={accentColor}
                    >
                        {labels.submit}
                    </Button>
                </form>
            </>
        );
    }
}

StandardForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isGasChecked: PropTypes.bool.isRequired,
    isElectricityChecked: PropTypes.bool.isRequired,
    labels: PropTypes.shape({
        address: PropTypes.string,
        electricityConsumption: PropTypes.string,
        gasConsumption: PropTypes.string,
        submit: PropTypes.string,
        tariffCalculationFormEmpty: PropTypes.string,
        addressEmptyError: PropTypes.string,
        electricityCheckboxName: PropTypes.string,
        gasCheckboxName: PropTypes.string,
        zipEmptyError: PropTypes.string,
        consumptionEmptyError: PropTypes.string,
        minimalElectricityConsumptionError: PropTypes.string,
        maximumElectricityConsumptionError: PropTypes.string,
        minimalGasConsumptionError: PropTypes.string,
        maximumGasConsumptionError: PropTypes.string
    }),
    isSubmitButtonDisabled: PropTypes.bool,
    locale: PropTypes.string,
    consumptionOptions: PropTypes.shape({}),
    energyTypes: PropTypes.arrayOf(PropTypes.string),
    validationConfig: PropTypes.shape({
        minimalElectricityConsumption: PropTypes.number,
        minimalGasConsumption: PropTypes.number,
        maximumElectricityConsumption: PropTypes.number,
        maximumGasConsumption: PropTypes.number
    }),
    consumptionIconsType: PropTypes.string,
    inputBackground: PropTypes.string,
    accentColor: PropTypes.string,
    streetName: PropTypes.string,
    cityName: PropTypes.string,
    houseNumber: PropTypes.string,
    zip: PropTypes.string,
    electricityConsumption: PropTypes.string,
    gasConsumption: PropTypes.string,
    address: PropTypes.string,
    submitDataError: PropTypes.string,
    isGoogleScriptLoaded: PropTypes.bool,
    isZipMode: PropTypes.bool
};

StandardForm.defaultProps = {
    labels: DEFAULT_LABELS,
    locale: undefined,
    consumptionOptions: undefined,
    isSubmitButtonDisabled: false,
    energyTypes: undefined,
    validationConfig: DEFAULT_VALIDATION_CONFIG,
    consumptionIconsType: undefined,
    inputBackground: undefined,
    accentColor: undefined,
    address: undefined,
    streetName: undefined,
    cityName: undefined,
    houseNumber: undefined,
    zip: undefined,
    electricityConsumption: undefined,
    gasConsumption: undefined,
    submitDataError: undefined,
    isGoogleScriptLoaded: undefined,
    isZipMode: false
};
