import React from 'react';
import PropTypes from 'prop-types';

import './FooterShort.css';

export default function FooterShort({ className, id, navItems, logoSource }) {
    return (
        <footer className={`ltn-footer-short ${className}`} id={id}>
            <nav className="ltn-footer-short-nav">
                <ul className="ltn-footer-short-nav-list">
                    {navItems.map(item => (
                        <li key={item.href}>
                            <a className="ltn-footer-short-nav-list-link" href={item.href}>
                                {item.label}
                            </a>
                            <span className="ltn-footer-short-links-delimiter">|</span>
                        </li>
                    ))}
                </ul>
            </nav>
            <picture className="ltn-footer-short-logo">
                <img className="lazyload" data-src={logoSource.svg} alt="stromee logo" width="33" height="33" />
            </picture>
        </footer>
    );
}

FooterShort.propTypes = {
    className: PropTypes.string,
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    logoSource: PropTypes.shape({
        svg: PropTypes.string.isRequired
    }).isRequired,
    id: PropTypes.string.isRequired
};

FooterShort.defaultProps = {
    className: ''
};
