import React from 'react';
import { Footer, LandingHeader, Loader, ProducerData, ModalWindow } from 'lition-shared-components';
import get from 'lodash/get';
import classNames from 'classnames';

import {
    APP_MODES,
    DEFAULT_CONSUMPTION,
    DEFAULT_ENERGY_TYPE,
    DEFAULT_PAGE_ID,
    HAVE_NO_PRODUCTS_MESSAGE,
    TRADING_PLATFORM_URL,
    CALC_FORM_MODES
} from '../../constants';

import CookiesAlert from '../__organisms__/CookiesAlert';
import Toast from '../__organisms__/Toast';
import FooterShort from '../__organisms__/FooterShort';
import FooterV2 from '../__organisms__/FooterV2';
import TariffCalculationFormV2 from '../__organisms__/TariffCalculationFormV2';
import ProducerTariff from '../__organisms__/ProducerTariff';

import * as GTM from '../../services/helpers/gtm';
import Adapter from '../../services/adapters/contentful';
import { getCurrentTariffCalculatorEnergyData } from '../../services/helpers/energy';
import { filterProducersByType, prepareProducerSpecification } from '../../services/helpers/producers';
import { richTextToHtml } from '../../services/helpers/contentful';
import { formatPrice } from '../../services/helpers/prices';
import { isPuppeteerEnv } from '../../services/helpers/env';

export function renderToast(specificErrorMessage) {
    const { commonServerErrorDetails } = this.state;

    const errorMessage =
        commonServerErrorDetails?.message ||
        specificErrorMessage?.value?.message ||
        (commonServerErrorDetails && 'Something went wrong. Try again or contact us.');

    const requestId = isPuppeteerEnv() ?
        null :
        commonServerErrorDetails?.requestId || specificErrorMessage?.value?.requestId;

    const clearedErrorState = {
        commonServerErrorDetails: null
    };

    if (specificErrorMessage?.key) {
        clearedErrorState[specificErrorMessage?.key] = null;
    }

    return errorMessage ? (
        <Toast
            type="error"
            message={`Problem: ${errorMessage}${requestId ? ` Request id: #${requestId}` : ''}`}
            onCloseClick={() => this.setState(clearedErrorState)}
        />
    ) : null;
}

export function renderLoader(isLoading) {
    const isShowLoader = this.props.isLoading || isLoading;
    return isShowLoader ? <Loader show={isShowLoader} /> : null;
}

export function renderDynamicHeader(fields, showSubmenu = true) {
    const { query: { identifier = DEFAULT_PAGE_ID, code } = {} } = this.props;

    if (!fields) {
        return null;
    }

    return (
        <LandingHeader
            className="landing-header"
            backgroundColor={fields.backgroundColor}
            boxShadow={fields.boxShadow}
            height={fields.height}
            logos={fields.logoList}
            logoText={fields.logoText}
            pageIdentifier={identifier}
            loginLink={get(fields, 'loginLink', TRADING_PLATFORM_URL)}
            loginIcon={get(fields, 'loginIcon.fields.file.url')}
            topRightContent={fields.topRightContent}
            textColor={fields.textColor}
            submenu={showSubmenu ? fields.submenu : null}
            salesPartnerId={showSubmenu ? code : null}
        />
    );
}

// TODO: This is an unsafe method. Need to refactor:
// Create an adapter, check all required and optional props, split each footer into a rendering method
export function renderDynamicFooter() {
    const { content, isShortFooter } = this.props;

    const footerContent = content?.footer?.fields;

    if (!footerContent) {
        return null;
    }

    let footer;

    if (isShortFooter) {
        footer = (
            <FooterShort
                id="footer"
                navItems={[
                    ...footerContent.navigationItems.map(navItem => ({
                        label: navItem.fields.text,
                        href: navItem.fields.url
                    }))
                ]}
                logoSource={{ svg: footerContent.logo?.fields?.file?.url }}
            />
        );
    } else if (footerContent.type === 'partnerFooter') {
        footer = (
            <FooterV2
                id="footer"
                className="ltn-de-footer"
                contactInfo={{
                    email: footerContent.email,
                    addressHtml: footerContent.addressHtml,
                    hotLineHtml: footerContent.phone
                }}
                navItems={[
                    {
                        headlineText: footerContent.labels.sectionHeadings.partner,
                        items: [
                            ...footerContent.exploreNavigationItems.map(navItem => ({
                                label: navItem.fields.text,
                                href: navItem.fields.url
                            }))
                        ]
                    },
                    {
                        headlineText: footerContent.labels.sectionHeadings.legal,
                        items: [
                            ...footerContent.navigationItems.map(navItem => ({
                                label: navItem.fields.text,
                                href: navItem.fields.url
                            }))
                        ]
                    }
                ]}
                logoSource={{ svg: footerContent.logo.fields.file.url, alt: footerContent.logo.fields.description }}
                footerType={footerContent.type}
                socialLogos={footerContent.socialMediaLogos.map(logo => ({
                    svg: logo.fields.imageSource.fields.file.url,
                    url: logo.fields.link
                }))}
                headlineTexts={{
                    community: footerContent.labels.sectionHeadings.community,
                    contact: footerContent.labels.sectionHeadings.contact
                }}
                onSocialIconClick={GTM.addSocialIconClickDataToDL}
            />
        );
    } else {
        footer = (
            <Footer
                id="footer"
                className="ltn-de-footer"
                contactInfo={{
                    phone: footerContent.phone,
                    email: footerContent.email,
                    addressHtml: footerContent.addressHtml
                }}
                navItems={[
                    {
                        headlineText: 'Legal',
                        items: [
                            ...footerContent.navigationItems.map(navItem => ({
                                label: navItem.fields.text,
                                href: navItem.fields.url
                            }))
                        ]
                    }
                ]}
                logoSource={{ svg: footerContent.logo.fields.file.url }}
            />
        );
    }

    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                            .ltn-de-partners-sign-up .ltn-cookies-disclaimer,
                            .ltn-de-partners-landing .ltn-cookies-disclaimer,
                            .ltn-de-partners-contract .ltn-cookies-disclaimer {
                                background: ${content?.accentColor};
                                opacity: 0.97;
                            }
                         `
                }}
            />
            <CookiesAlert />
            {footer}
        </>
    );
}

export function renderCommonMessageTemplate(message = HAVE_NO_PRODUCTS_MESSAGE) {
    return (
        <div className="ltn-de-common-message-template-container">
            <h1>{message}</h1>
        </div>
    );
}

export function renderTariffCalculationForm({
    tariffCalculationForm,
    className,
    isSubmitButtonDisabled,
    metaData = { place: GTM.EVENT_DETAILS.TARIFF_CALC_FORM_SUBMIT.LABEL.TOP }
} = {}) {
    const { query, content, compareProductServerErrorDetails } = this.props;

    const landingDetails = content?.landingDetails || {};
    const { energyTypes, language, config = {} } = landingDetails;

    // FIXME: Should get tariffCalculationForm only from function params and landingContent
    const tariffCalculationFormData =
        tariffCalculationForm || content?.landingContent?.tariffCalculationForm || content?.tariffCalculationForm;

    const {
        inputBackground,
        tariffFormMode,
        labels,
        consumptionOptions,
        consumptionIconsType,
        formType,
        validationConfig,
        id
    } = Adapter.getAdaptedTariffCalculationFormContent(tariffCalculationFormData) || {};

    const { electricityConsumption, isElectricitySelected, gasConsumption, isGasSelected } =
        getCurrentTariffCalculatorEnergyData({ query, availableEnergyTypes: energyTypes });

    const { compareProductPostcodeErrorMessage, consumptionNoTariffErrorMessage } = this.state;
    const consumptionNoTariffError = consumptionNoTariffErrorMessage || compareProductServerErrorDetails?.message;

    // TODO: Need rename the formMode to calcFormMode and also formType to calcFormType in DB config!
    if (config.formMode === CALC_FORM_MODES.NONE) {
        return null;
    }

    return (
        <TariffCalculationFormV2
            key={id}
            className={classNames('ltn-de-partners-landing-tariff-calculator--v2', className)}
            submitDataError={compareProductPostcodeErrorMessage || consumptionNoTariffError}
            labels={labels}
            consumptionOptions={consumptionOptions}
            onSubmit={this.handleTariffCalculationFormSubmit}
            data={query}
            locale={language}
            energyTypes={energyTypes}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            consumptionIconsType={consumptionIconsType}
            metaData={metaData}
            formType={formType}
            styles={{ inputBackground, accentColor: content?.accentColor }}
            electricityConsumption={electricityConsumption}
            isElectricityChecked={isElectricitySelected}
            gasConsumption={gasConsumption}
            isGasChecked={isGasSelected}
            // TODO: Need to get this from db
            formMode={tariffFormMode === APP_MODES.V2 ? 'address' : 'zip'}
        />
    );
}

export function renderTariffCalculatorStyles() {
    const {
        content: { accentColor }
    } = this.props;

    return (
        <style
            // TODO: Add styles for tariff calculation v2 @Anton
            // TODO: remove hardcoded css for submit button after updating prod @Pavel
            dangerouslySetInnerHTML={{
                __html: `
                    .ltn-de-tariff-calculation-form-container .ltn-de-form-tabs .calculation-form-tab--active p {
                        color: ${accentColor};
                    }

                    .ltn-de-tariff-calculation-form-container .ltn-de-form-tabs .calculation-form-tab--active p > svg {
                        background: ${accentColor};
                    }

                    .ltn-de-partners-landing-tariff-calculator .ltn-button {
                        background: ${accentColor};
                        color: white;
                    }

                    .ltn-de-partners-landing-tariff-calculator .ltn-consumption-select-energy path,
                    .ltn-de-partners-landing-tariff-calculator .ltn-location-input svg,
                    .ltn-de-partners-landing-tariff-calculator .ltn-select-option--selected svg > path {
                        fill: ${accentColor};
                    }
                `
            }}
        />
    );
}

// The render method also uses for contract power plant tariffs (pages/partners/contract/index.js)
// TODO: Cover by tests
export function renderProducers() {
    const LAZY_IMAGES_OFFSET = 4;

    const {
        content: { pageContent = {}, landingContent, landingDetails } = {},
        producers,
        isEcoenergy,
        query: { energyType = DEFAULT_ENERGY_TYPE, HT: dayConsumption, NT: nightConsumption }
    } = this.props;

    const { activeProducerId, selectedProducerTypes } = this.state;

    if (!producers.length) {
        return this.renderCommonMessageTemplate(pageContent.noProducersError);
    }

    return filterProducersByType(producers, selectedProducerTypes).map((producer, index) => {
        const pricesNote = (() => {
            if (producer.pricesNote) {
                return producer.pricesNote;
            }
            return isEcoenergy ?
                richTextToHtml(get(pageContent, 'pricesNoteForEcoenergy.content')) :
                richTextToHtml(get(pageContent, 'pricesNote.content'));
        })();

        const priceAtKWh = producer.price ?
            formatPrice(producer.price, undefined, true) :
            formatPrice(producer?.data?.workingPrice);

        const priceAtKWhNT = producer.priceNT ?
            formatPrice(producer.priceNT, undefined, true) :
            formatPrice(producer?.data?.workingPriceNT);

        const basePrice = producer?.data?.monthlyBasePrice ?
            formatPrice(producer?.data?.monthlyBasePrice) :
            formatPrice(producer?.productData?.monthlyBasePrice);

        const isTimeDependingConsumption = !!(dayConsumption && nightConsumption);

        // TODO: add language to formatPrice method as second param @Anton
        return (
            <li key={producer.id} className="ltn-de-producers-list__item">
                <ProducerData
                    cardMode={landingContent?.producersContent?.fields?.cardMode}
                    className="ltn-de-producers-list__card"
                    producerId={producer.id}
                    labels={{
                        generalPrice: pageContent.producerCardGeneralPriceLabel,
                        savings: pageContent.producerCardSavingsLabel,
                        offerButton: pageContent.producerCardOfferButtonLabel,
                        soldOut: pageContent.producerCardSoldOutLabel,
                        month: pageContent.producerCardMonthLabel,
                        year: pageContent.producerCardYearLabel,
                        readMoreButton: pageContent.producerCardReadMoreButton,
                        signUpButton: pageContent.producerCardSignUpButton,
                        priceAtKWh: pageContent.priceAtKWhLabel,
                        basePrice: pageContent.basePriceLabel,
                        totalPriceDescription: landingContent?.producersContent?.fields?.labels?.totalPriceDescription
                    }}
                    producer={producer}
                    yearlySavings={producer.yearlySavings > 0 ? formatPrice(producer.yearlySavings) : undefined}
                    generalMonthlyPrice={formatPrice(producer.generalMonthlyPrice)}
                    pricesNote={pricesNote}
                    isFlipped={activeProducerId === producer.id}
                    onFlip={() => this.handleFlip(producer.id)}
                    signUpUrl={producer.signUpUrl}
                    producerDetailsUrl={producer.producerDetailsUrl}
                    onProducerDetailsClick={() => {
                        if (window.self !== window.top) {
                            window.parent.postMessage({ producer }, '*');
                        }
                        GTM.addProductDetailsClickDataToDL(
                            // FIXME: Need think about cases when user click this by keyboard (Enter)
                            // or mouse wheel (GTM)
                            producer,
                            landingDetails,
                            energyType
                        );
                    }}
                    // onSignUpClick
                    onOfferButtonClick={() => {
                        if (window.self !== window.top) {
                            window.parent.postMessage({ contract: producer }, '*');
                        }
                        GTM.addSelectedProductDataToDL(
                            // FIXME: Need think about cases when user click this by keyboard (Enter)
                            // or mouse wheel (GTM)
                            producer,
                            landingDetails,
                            energyType
                        );
                    }}
                    isLazyImg={index >= LAZY_IMAGES_OFFSET}
                    priceAtKWh={priceAtKWh}
                    basePrice={basePrice}
                    onTariffDetailsButtonClick={() => this.openProducerTariff(producer)}
                    priceAtKWhNT={isTimeDependingConsumption ? priceAtKWhNT : undefined}
                />
            </li>
        );
    });
}

// The render method also uses for contract power plant tariffs (pages/partners/contract/index.js)
export function renderProducerTariffModal() {
    const {
        query: { consumption = DEFAULT_CONSUMPTION, iframe },
        content: { landingContent } = {}
    } = this.props;

    const { isProducerTariffModalOpen, activeProducerData } = this.state;

    const { tariffAdvantages } = Adapter.getTariffModalConfig(landingContent);

    return (
        <ModalWindow
            className="ltn-de-producer-page__producer-tariff-modal"
            isOpen={isProducerTariffModalOpen}
            onModalClose={this.closeProducerTariff}
            isPageInIframe={!!iframe}
            style={{ minHeight: 'calc(var(--frame-height, 100vh) - 64px)' }}
        >
            <ProducerTariff
                className="ltn-de-producer-page__producer-tariff"
                producerSpecification={
                    activeProducerData ? prepareProducerSpecification(activeProducerData, consumption) : {}
                }
                tariffAdvantages={tariffAdvantages}
            />
        </ModalWindow>
    );
}
