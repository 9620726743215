import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'lition-shared-components';

import { ElectricityIcon, EnergyMeter, HT, NT } from '../../../__icons__';
import MeteringInput from '../../../__atoms__/MeteringInput';

import { ENERGY_TYPES } from '../../../../constants';

import {
    TIME_DEPENDING_FIELD_NAMES as FIELD_NAMES,
    TIME_DEPENDING_DEFAULT_LABELS as DEFAULT_LABELS,
    MOBILITY_FORM_METER_TYPE_OPTIONS as METER_TYPE_OPTIONS,
    CONSUMPTION_ICONS_TYPE
} from '../constants';

import { getConsumptionIcons, getMobilityConsumptionOptions } from '../utils';

// TODO: add to styleguide
export default function MobilityForm({ labels, onChange, onSubmit, formData, children }) {
    const renderDoubleMeterings = () => (
        <>
            <MeteringInput
                className="ltn-de-electricity-consumption ltn-de-tariff-calc-form-field"
                onChange={onChange}
                name={FIELD_NAMES.dayConsumption}
                inputValue={formData[FIELD_NAMES.dayConsumption]}
                placeholder={labels.consumptionPlaceholder}
                rangerSliderIcons={getConsumptionIcons(
                    ENERGY_TYPES.ELECTRICITY,
                    CONSUMPTION_ICONS_TYPE.electroMobility
                )}
                measurerIcon={<HT />}
                meter={DEFAULT_LABELS.meter}
                consumptionOptions={getMobilityConsumptionOptions(formData[FIELD_NAMES.meterType]).dayConsumption}
            />
            <MeteringInput
                className="ltn-de-electricity-consumption ltn-de-tariff-calc-form-field"
                onChange={onChange}
                name={FIELD_NAMES.nightConsumption}
                inputValue={formData[FIELD_NAMES.nightConsumption]}
                placeholder={labels.consumptionPlaceholder}
                rangerSliderIcons={getConsumptionIcons(
                    ENERGY_TYPES.ELECTRICITY,
                    CONSUMPTION_ICONS_TYPE.electroMobility
                )}
                measurerIcon={<NT />}
                meter={DEFAULT_LABELS.meter}
                consumptionOptions={getMobilityConsumptionOptions(formData[FIELD_NAMES.meterType]).nightConsumption}
            />
        </>
    );

    const renderSingleMetering = () => (
        <MeteringInput
            className="ltn-de-electricity-consumption ltn-de-tariff-calc-form-field"
            onChange={onChange}
            name={FIELD_NAMES.consumption}
            inputValue={formData[FIELD_NAMES.consumption]}
            placeholder={labels.consumptionPlaceholder}
            rangerSliderIcons={getConsumptionIcons(ENERGY_TYPES.ELECTRICITY, CONSUMPTION_ICONS_TYPE.electroMobility)}
            measurerIcon={<ElectricityIcon />}
            meter={DEFAULT_LABELS.meter}
            consumptionOptions={getMobilityConsumptionOptions(formData[FIELD_NAMES.meterType]).consumption}
        />
    );

    const isDoubleSelectMeter = formData[FIELD_NAMES.meterType] === METER_TYPE_OPTIONS[1].value;

    return (
        <form onSubmit={onSubmit}>
            <Select
                name={FIELD_NAMES.meterType}
                className="ltn-de-select_version2 ltn-de-tariff-calc-form-field"
                placeholder={labels.meterTypePlaceholder}
                value={formData[FIELD_NAMES.meterType]}
                options={METER_TYPE_OPTIONS}
                onChange={({ name, value }) => onChange({ target: { name, value } })}
                icon={<EnergyMeter />}
            />
            {isDoubleSelectMeter ? renderDoubleMeterings() : renderSingleMetering()}
            {children}
        </form>
    );
}

MobilityForm.propTypes = {
    labels: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formData: PropTypes.shape().isRequired,
    children: PropTypes.element.isRequired
};
