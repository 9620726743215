import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput } from 'lition-shared-components';

import LocationInputV2 from '../../../__atoms__/LocationInputV2/LocationInputV2';
import { LocationIcon } from '../../../__icons__';

import {
    TIME_DEPENDING_DEFAULT_LABELS as DEFAULT_LABELS,
    TIME_DEPENDING_FIELD_NAMES as FIELD_NAMES,
    LOCATION_FIELD_NAMES,
    FORM_TYPES,
    DEFAULT_HOUSE_NUMBER
} from '../constants';

import {
    getAddress,
    getPreparedSubmitMobilityData,
    getPreparedSubmitHeatData,
    getMeterTypeByEnergySubTypeId,
    getActiveRadioButtonByEnergySubTypeId
} from '../utils';

import { validateTimeDependingFormData } from '../validation';

import HeatForm from './HeatForm';
import MobilityForm from './MobilityForm';

// TODO: Integrate with contentful
// TODO: Add background and accentColor for fields

export default function TimeDependingForm({
    labels,
    locale,
    isSubmitButtonDisabled,
    formType,
    onSubmit,
    data,
    isZipMode
}) {
    const [formData, setFormData] = useState({
        [FIELD_NAMES.consumption]: data?.[FIELD_NAMES.consumption],
        [FIELD_NAMES.dayConsumption]: data?.dayConsumption,
        [FIELD_NAMES.nightConsumption]: data?.nightConsumption,
        [FIELD_NAMES.meterType]: getMeterTypeByEnergySubTypeId(data?.energySubTypeId)
    });

    const [locationData, setLocationData] = useState({
        [LOCATION_FIELD_NAMES.address]: data?.[LOCATION_FIELD_NAMES.address],
        [LOCATION_FIELD_NAMES.location]: data?.[LOCATION_FIELD_NAMES.location],
        [LOCATION_FIELD_NAMES.cityName]: data?.[LOCATION_FIELD_NAMES.cityName],
        [LOCATION_FIELD_NAMES.houseNumber]: data?.[LOCATION_FIELD_NAMES.houseNumber],
        [LOCATION_FIELD_NAMES.streetName]: data?.[LOCATION_FIELD_NAMES.streetName],
        [LOCATION_FIELD_NAMES.zip]: data?.[LOCATION_FIELD_NAMES.zip]
    });

    const [submitError, setSubmitError] = useState();

    const [activeRadioButtonName, setActiveRadioButtonName] = useState(
        getActiveRadioButtonByEnergySubTypeId(data?.energySubTypeId)
    );

    const getPreparedSubmitData = submitData => {
        const addressData = {
            [LOCATION_FIELD_NAMES.zip]: submitData[LOCATION_FIELD_NAMES.zip],
            [LOCATION_FIELD_NAMES.houseNumber]: submitData[LOCATION_FIELD_NAMES.houseNumber],
            [LOCATION_FIELD_NAMES.streetName]: submitData[LOCATION_FIELD_NAMES.streetName],
            [LOCATION_FIELD_NAMES.cityName]: submitData[LOCATION_FIELD_NAMES.cityName]
        };

        if (formType === FORM_TYPES.mobility) {
            const preparedSubmitMobilityData = getPreparedSubmitMobilityData(submitData);
            return { ...addressData, ...preparedSubmitMobilityData };
        }

        const preparedSubmitHeatData = getPreparedSubmitHeatData(submitData, activeRadioButtonName);
        return { ...addressData, ...preparedSubmitHeatData };
    };

    const handleSubmit = async event => {
        event.preventDefault();

        const primaryValidationError = validateTimeDependingFormData({
            labels,
            energySubTypeName: activeRadioButtonName,
            [LOCATION_FIELD_NAMES.zip]: locationData[LOCATION_FIELD_NAMES.zip],
            [LOCATION_FIELD_NAMES.streetName]: locationData[LOCATION_FIELD_NAMES.streetName],
            [LOCATION_FIELD_NAMES.cityName]: locationData[LOCATION_FIELD_NAMES.cityName],
            [FIELD_NAMES.consumption]: formData[FIELD_NAMES.consumption],
            [FIELD_NAMES.dayConsumption]: formData[FIELD_NAMES.dayConsumption],
            [FIELD_NAMES.nightConsumption]: formData[FIELD_NAMES.nightConsumption],
            [FIELD_NAMES.meterType]: formData[FIELD_NAMES.meterType],
            isZipMode
        });

        if (primaryValidationError) {
            setSubmitError(primaryValidationError);
            return;
        }

        const {
            [LOCATION_FIELD_NAMES.zip]: zip,
            [LOCATION_FIELD_NAMES.houseNumber]: houseNumber,
            [LOCATION_FIELD_NAMES.streetName]: streetName,
            [LOCATION_FIELD_NAMES.location]: location,
            [LOCATION_FIELD_NAMES.cityName]: cityName
        } = locationData;

        let address = { zip };

        // TODO: Need to think how we can refactor this @Anton
        if (!isZipMode) {
            const possibleAddress = await getAddress({ zip, houseNumber, streetName, location });

            address = {
                [LOCATION_FIELD_NAMES.zip]: possibleAddress[LOCATION_FIELD_NAMES.zip] || zip,
                [LOCATION_FIELD_NAMES.streetName]: possibleAddress[LOCATION_FIELD_NAMES.streetName] || streetName,
                [LOCATION_FIELD_NAMES.cityName]: possibleAddress[LOCATION_FIELD_NAMES.cityName] || cityName,
                [LOCATION_FIELD_NAMES.houseNumber]:
                    possibleAddress[LOCATION_FIELD_NAMES.houseNumber] || houseNumber || DEFAULT_HOUSE_NUMBER
            };

            const validationError = validateTimeDependingFormData({
                labels,
                energySubTypeName: activeRadioButtonName,
                [LOCATION_FIELD_NAMES.zip]: address[LOCATION_FIELD_NAMES.zip],
                [LOCATION_FIELD_NAMES.streetName]: address[LOCATION_FIELD_NAMES.streetName],
                [LOCATION_FIELD_NAMES.cityName]: address[LOCATION_FIELD_NAMES.cityName],
                [FIELD_NAMES.consumption]: formData[FIELD_NAMES.consumption],
                [FIELD_NAMES.dayConsumption]: formData[FIELD_NAMES.dayConsumption],
                [FIELD_NAMES.nightConsumption]: formData[FIELD_NAMES.nightConsumption],
                [FIELD_NAMES.meterType]: formData[FIELD_NAMES.meterType]
            });

            if (validationError) {
                setSubmitError(validationError);
                return;
            }
        }

        const preparedSubmitData = getPreparedSubmitData({
            [LOCATION_FIELD_NAMES.zip]: address[LOCATION_FIELD_NAMES.zip],
            [LOCATION_FIELD_NAMES.streetName]: address?.[LOCATION_FIELD_NAMES.streetName],
            [LOCATION_FIELD_NAMES.cityName]: address?.[LOCATION_FIELD_NAMES.cityName],
            [LOCATION_FIELD_NAMES.houseNumber]: address?.[LOCATION_FIELD_NAMES.houseNumber],
            [FIELD_NAMES.consumption]: formData[FIELD_NAMES.consumption],
            [FIELD_NAMES.dayConsumption]: formData[FIELD_NAMES.dayConsumption],
            [FIELD_NAMES.nightConsumption]: formData[FIELD_NAMES.nightConsumption],
            [FIELD_NAMES.meterType]: formData[FIELD_NAMES.meterType]
        });

        onSubmit(preparedSubmitData);
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setSubmitError();
    };

    const handleAddressChange = address => {
        if (!address) {
            return;
        }

        const addressData = {
            [LOCATION_FIELD_NAMES.cityName]: address?.[LOCATION_FIELD_NAMES.cityName],
            [LOCATION_FIELD_NAMES.houseNumber]: address?.[LOCATION_FIELD_NAMES.houseNumber],
            [LOCATION_FIELD_NAMES.streetName]: address?.[LOCATION_FIELD_NAMES.streetName],
            [LOCATION_FIELD_NAMES.zip]: address?.[LOCATION_FIELD_NAMES.zip]
        };

        setLocationData({ ...locationData, ...addressData });
    };

    const handleRadioButtonChange = event => {
        const { name } = event.target;
        setActiveRadioButtonName(name);

        setFormData({
            ...formData,
            [FIELD_NAMES.consumption]: undefined,
            [FIELD_NAMES.dayConsumption]: undefined,
            [FIELD_NAMES.nightConsumption]: undefined
        });
    };

    const renderFormChildren = () => (
        <>
            {isZipMode ? (
                // TODO: Need to add a zip icon for this input
                <TextInput
                    className="ltn-de-text-input_version2"
                    name={LOCATION_FIELD_NAMES.zip}
                    placeholder={labels.zipPlaceholder}
                    value={locationData.zip}
                    onChange={event => handleAddressChange({ [LOCATION_FIELD_NAMES.zip]: event.target.value })}
                    icon={<LocationIcon />}
                />
            ) : (
                <LocationInputV2
                    className="ltn-de-tariff-calc-form-field"
                    onAddressChange={handleAddressChange}
                    placeholder={labels.locationPlaceholder}
                    value={locationData[LOCATION_FIELD_NAMES.address]}
                    locale={locale}
                    name={LOCATION_FIELD_NAMES.location}
                    onChange={handleChange}
                    isGoogleScriptLoaded={false}
                />
            )}
            <TextInput.Error>{submitError}</TextInput.Error>
            <Button className="ltn-de-tariff-calculator-form-submit" disabled={isSubmitButtonDisabled} type="submit">
                {labels.submit}
            </Button>
        </>
    );

    return (
        <div className="ltn-tariff-time-depending-calculator-form-container">
            {formType === FORM_TYPES.heat ? (
                <HeatForm
                    labels={labels}
                    onChange={handleChange}
                    formData={formData}
                    onSubmit={handleSubmit}
                    activeRadioButtonName={activeRadioButtonName}
                    onRadioButtonChange={handleRadioButtonChange}
                >
                    {renderFormChildren()}
                </HeatForm>
            ) : (
                <MobilityForm labels={labels} onChange={handleChange} formData={formData} onSubmit={handleSubmit}>
                    {renderFormChildren()}
                </MobilityForm>
            )}
        </div>
    );
}

TimeDependingForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    labels: PropTypes.shape({
        locationPlaceholder: PropTypes.string,
        submit: PropTypes.string,
        zipPlaceholder: PropTypes.string
    }),
    locale: PropTypes.string,
    isSubmitButtonDisabled: PropTypes.bool,
    formType: PropTypes.string,
    data: PropTypes.shape({
        dayConsumption: PropTypes.string,
        energySubTypeId: PropTypes.string,
        nightConsumption: PropTypes.string
    }),
    isZipMode: PropTypes.bool
};

TimeDependingForm.defaultProps = {
    labels: DEFAULT_LABELS,
    locale: undefined,
    isSubmitButtonDisabled: false,
    formType: undefined,
    data: undefined,
    isZipMode: false
};
