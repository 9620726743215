export function nodeContentToHtml(content) {
    return content
        .map(node => {
            let openTags = '';
            let closeTags = '';
            if (node.marks) {
                node.marks.forEach(mark => {
                    if (mark.type === 'bold') {
                        openTags = `<strong>${openTags}`;
                        closeTags += '</strong>';
                    }
                    if (mark.type === 'italic') {
                        openTags = `<em>${openTags}`;
                        closeTags += '</em>';
                    }
                    if (mark.type === 'underline') {
                        openTags = `<u>${openTags}`;
                        closeTags += '</u>';
                    }
                });
            }
            if (node.nodeType === 'text') {
                return openTags + node.value + closeTags;
            }

            return '';
        })
        .join('');
}

export function richTextToHtml(content = []) {
    return content
        .map(node => {
            if (node.nodeType === 'paragraph') {
                return `<p>${nodeContentToHtml(node.content)}</p>`;
            }
            return '';
        })
        .join('');
}
