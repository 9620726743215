import get from 'lodash/get';
import { roundNumber } from './converter';
import { getYearlyWorkingPrice, calcYearPrice, formatPrice } from './prices';

import { PRODUCER_FILTER_OPTIONS, PRODUCER_TYPES, DEFAULT_LOCALE } from '../../constants';

// FIXME: Filter the data on BE or in an api method
export function filterProducersByType(producerList, selectedTypes) {
    return producerList.filter(
        producer => !selectedTypes || selectedTypes.includes(producer.type) || producer.type === PRODUCER_TYPES.default
    );
}

export function getFilterProducerTypesByQuery(query = []) {
    const filterTypes = PRODUCER_FILTER_OPTIONS.map(option => option.type);
    const queryFilterTypes = get(query, 'filterBy', '').split(',');

    if (queryFilterTypes.length && queryFilterTypes.every(type => filterTypes.includes(type))) {
        return queryFilterTypes;
    }

    return filterTypes;
}

function getGuaranteePricePeriodValue(guaranteePricePeriodData) {
    const nothingValueDe = 'Keine';
    const fromValueDe = 'Bis';
    const monthDe = 'Monat';
    const monthsDe = 'Monate';

    const { type, value } = guaranteePricePeriodData;
    switch (type) {
        case 'months':
            return `${value} ${value > 1 ? monthsDe : monthDe}`;
        case 'date':
            return `${fromValueDe} ${value}`;
        default:
            return nothingValueDe;
    }
}

// TODO: in this method used the same calculations as in producer-details, check them and unite
// TODO: Cover by tests
export const prepareProducerSpecification = (producerData = {}, consumption) => {
    const roundAndLocalizationNumber = (number, digits = 2) => roundNumber(number, digits).toLocaleString(DEFAULT_LOCALE);

    const isStandardProducerData = !!producerData.productData; // TODO: remove this variable, this is necessary only because producers array elements have different format for contract pages (very dangerous)

    const productData = isStandardProducerData ? producerData.productData : producerData.data;
    const { guaranteePricePeriod: guaranteePricePeriodData = {}, initialDuration = 0 } = productData;

    const guaranteePricePeriodValue = getGuaranteePricePeriodValue(guaranteePricePeriodData);
    const contractPeriodDescription = initialDuration > 0 ?
        `${productData.initialDuration} Monate` : 'Monatlich kündbar';
    const contractPeriodDescriptionExtended = initialDuration > 0 ?
        `${producerData.productData.initialDuration} Monate` : 'Monatlich kündbar';
    const yearlyWorkingPrice = isStandardProducerData ?
        getYearlyWorkingPrice(producerData.price * 100, consumption).toLocaleString(DEFAULT_LOCALE) :
        getYearlyWorkingPrice(productData.workingPrice, consumption).toLocaleString(DEFAULT_LOCALE);
    const yearlyPrice = isStandardProducerData ?
        calcYearPrice(producerData.price, productData?.monthlyBasePrice, consumption) :
        calcYearPrice(productData.workingPrice / 100, productData?.monthlyBasePrice, consumption);

    const VAT_TARIFF = 0.19;

    return {
        consumption: roundAndLocalizationNumber(+consumption, 0),
        name: producerData.name,
        monthlyBasePrice: roundAndLocalizationNumber(productData?.monthlyBasePrice),
        monthlyBasePriceNetto: roundAndLocalizationNumber(productData?.monthlyBasePrice / (1 + VAT_TARIFF)), // TODO: Calculate this on BE! check productData.basePriceNetto (no for contract pages)
        workingPrice: isStandardProducerData ? // TODO: Calculate this on BE! check productData.workingPrice
            formatPrice(producerData.price, undefined, true) :
            roundAndLocalizationNumber(productData.workingPrice),
        workingPriceNetto: isStandardProducerData ? // TODO: Calculate this on BE! check productData.workingPriceNetto, remove this calculations
            formatPrice(producerData.price / (1 + VAT_TARIFF), undefined, true) :
            roundAndLocalizationNumber(productData.workingPriceNetto),
        yearlyWorkingPrice, // TODO: Calculate this on BE! Check productData.yearlyPrice
        yearlyPrice, // TODO: Calculate this on BE! check productData.yearlyPrice
        contractPeriodDescription,
        contractPeriodDescriptionExtended,
        guaranteePricePeriod: guaranteePricePeriodValue,
        monthlyPrice: roundAndLocalizationNumber(producerData.generalMonthlyPrice), // TODO: need to check
        yearlySavings:
            producerData.yearlySavings > 0 ? roundAndLocalizationNumber(producerData.yearlySavings) : undefined, // TODO: need to check
        producerDetailsUrl: producerData.producerDetailsUrl,
        signUpUrl: producerData.signUpUrl,
        paymentPeriod: 'Monatlich', // Hardcoded
        terminationPeriod: '1', // Hardcoded We let our customers out of the contract if they want to without locking them up
        provider: 'homee GmbH'
    };
};
