export const DEFAULT_LABELS = {
    address: 'Straße und Hausnummer',
    electricityConsumption: 'Verbrauch',
    gasConsumption: 'Verbrauch',
    tariffCalculationFormEmpty: 'Deine Eingaben sind ungültig. Bitte überprüfe deine Angaben.',
    addressEmptyError: 'Bitte gib eine gültige Adresse.',
    consumptionEmptyError: 'Bitte geben Sie den Verbrauch ein.',
    submit: 'Preis berechnen',
    electricityCheckboxName: 'ÖKOSTROM',
    gasCheckboxName: 'GAS',
    zipEmptyError: 'Unter dieser Adresse wurde keine PLZ gefunden. Bitte geben Sie die Straße und Hausnummer ein.',
    minimalElectricityConsumptionError: 'Ihr Verbrauch muss mindestens {{minimalValidConsumption}}kWh betragen.',
    minimalGasConsumptionError: 'Ihr Verbrauch muss mindestens {{minimalValidConsumption}}kWh betragen.',
    maximumElectricityConsumptionError: 'Ihr Verbrauch muss unter {{maximumValidConsumption}}kWh betragen.',
    maximumGasConsumptionError: 'Ihr Verbrauch muss unter {{maximumValidConsumption}}kWh betragen.',
    meter: 'kWh',
    zipFieldEmptyError: 'Bitte geben Sie die Postleitzahl ein.',
    zipPlaceholder: 'Deine PLZ'
};

export const CUBIC_METER = 'm²';

export const DEFAULT_VALIDATION_CONFIG = {
    minimalElectricityConsumption: 1,
    minimalGasConsumption: 1000,
    maximumElectricityConsumption: 99999,
    maximumGasConsumption: 1000000
};

export const DEFAULT_HOUSE_NUMBER = '1';

export const CONSUMPTION_ICONS_TYPE = {
    electroMobility: 'e-mobility'
};

export const LOCATION_FIELD_NAMES = {
    address: 'address',
    location: 'location',
    cityName: 'cityName',
    houseNumber: 'houseNumber',
    streetName: 'streetName',
    zip: 'zip'
};

export const FORM_TYPES = {
    heat: 'heatOnly',
    mobility: 'mobilityOnly'
};

export const TIME_DEPENDING_DEFAULT_LABELS = {
    heatPumpRadioButton: 'WÄRMEPUMPE',
    nightStorageHeatingRadioButton: 'NACHTSPEICHERHEIZUNG',
    doubleTariffMeterRadioButton: 'HAUSHALT MIT DOPPELTARIFZÄHLER',
    submit: 'Preis berechnen',
    locationPlaceholder: 'Adresse',
    consumptionPlaceholder: 'Verbrauch',
    meterTypePlaceholder: 'Zählerart',
    meterTypeEmptyError: 'Bitte geben Sie den Zählerart ein.',
    zipEmptyError: 'Unter dieser Adresse wurde keine PLZ gefunden. Bitte geben Sie die Straße und Hausnummer ein.',
    tariffCalculationFormEmpty: 'Deine Eingaben sind ungültig. Bitte überprüfe deine Angaben.',
    addressEmptyError: 'Bitte gib eine gültige Adresse.',
    consumptionEmptyError: 'Bitte geben Sie den Verbrauch ein.',
    meter: 'kWh',
    zipPlaceholder: 'Deine PLZ',
    zipFieldEmptyError: 'Bitte geben Sie die Postleitzahl ein.'
};

export const TIME_DEPENDING_FIELD_NAMES = {
    consumption: 'consumption',
    dayConsumption: 'HT',
    nightConsumption: 'NT',
    meterType: 'meterType'
};

export const TIME_DEPENDING_RADIO_BUTTON_NAMES = {
    heatPump: 'heatPump',
    nightStorageHeating: 'nightStorageHeating',
    doubleTariffMeter: 'doubleTariffMeter'
};

export const HEAT_FORM_METER_TYPE_OPTIONS = [
    { value: 'single', name: 'meterType', label: 'Eintarif' },
    { value: 'double', name: 'meterType', label: 'Doppeltarif' }
];

export const MOBILITY_FORM_METER_TYPE_OPTIONS = [
    { value: 'mobility', name: 'meterType', label: 'Eintarif (Wallbox für Ladestrom)' },
    { value: 'double', name: 'meterType', label: 'Doppeltarif (Wallbox Tag/Nacht für Ladestrom)' },
    { value: 'single', name: 'meterType', label: 'Eintarif (Home Solution mit Haushaltsstrom)' }
];

export const DIVISION_TOOLTIP_VALUES = {
    gas: 150,
    [TIME_DEPENDING_RADIO_BUTTON_NAMES.heatPump]: {
        consumption: 38,
        dayConsumption: 16,
        nightConsumption: 22
    },
    [TIME_DEPENDING_RADIO_BUTTON_NAMES.nightStorageHeating]: {
        consumption: 136,
        dayConsumption: 54,
        nightConsumption: 82
    },
    [TIME_DEPENDING_RADIO_BUTTON_NAMES.doubleTariffMeter]: {
        dayConsumption: 36,
        nightConsumption: 54
    }
};

export const HEAT_CONSUMPTION_OPTIONS = {
    [TIME_DEPENDING_RADIO_BUTTON_NAMES.heatPump]: {
        consumption: [{ value: '1900' }, { value: '3800' }, { value: '5700' }, { value: '7600' }, { value: '9500' }],
        dayConsumption: [{ value: '800' }, { value: '1600' }, { value: '2400' }, { value: '3200' }, { value: '4000' }],
        nightConsumption: [
            { value: '1100' },
            { value: '2200' },
            { value: '3300' },
            { value: '4400' },
            { value: '5500' }
        ]
    },
    [TIME_DEPENDING_RADIO_BUTTON_NAMES.nightStorageHeating]: {
        consumption: [
            { value: '6800' },
            { value: '13600' },
            { value: '20400' },
            { value: '27200' },
            { value: '34000' }
        ],
        dayConsumption: [
            { value: '2700' },
            { value: '5400' },
            { value: '8100' },
            { value: '10800' },
            { value: '13500' }
        ],
        nightConsumption: [
            { value: '4100' },
            { value: '8200' },
            { value: '12300' },
            { value: '16400' },
            { value: '20500' }
        ]
    },
    [TIME_DEPENDING_RADIO_BUTTON_NAMES.doubleTariffMeter]: {
        dayConsumption: [{ value: '1800' }, { value: '3600' }, { value: '5400' }, { value: '7200' }, { value: '9000' }],
        nightConsumption: [
            { value: '2700' },
            { value: '5400' },
            { value: '8100' },
            { value: '10800' },
            { value: '13500' }
        ]
    }
};

export const MOBILITY_CONSUMPTION_OPTIONS = {
    [MOBILITY_FORM_METER_TYPE_OPTIONS[0].value]: {
        consumption: [{ value: '1000' }, { value: '2000' }, { value: '3000' }, { value: '4000' }, { value: '5000' }]
    },
    [MOBILITY_FORM_METER_TYPE_OPTIONS[1].value]: {
        dayConsumption: [{ value: '300' }, { value: '600' }, { value: '900' }, { value: '1200' }, { value: '1500' }],
        nightConsumption: [{ value: '700' }, { value: '1400' }, { value: '2100' }, { value: '2800' }, { value: '3500' }]
    },
    [MOBILITY_FORM_METER_TYPE_OPTIONS[2].value]: {
        consumption: [{ value: '2600' }, { value: '3600' }, { value: '4600' }, { value: '5600' }, { value: '6600' }]
    }
};
